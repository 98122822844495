@import '../../../dependencies/scss/variables.scss';

.info {
  margin-top: 4px;
  font-size: 12px;
  font-family: 'Lato';
  text-align: right;
  line-height: 1.3;
  color: $gray-color-6;
}

.infoLink {
  font-size: 12px;
  font-family: 'Lato-Bold';
}
