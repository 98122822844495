@import '../../../dependencies/scss/variables.scss';

$zIndex: var(--zIndex);

@mixin modal-middle {
  top: 25%;
  position: relative;
  transform: translateY(-25%);
  animation-name: customModal_dialog-middle-in;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex;
  background-color: #000;
  display: block;
  opacity: 0.4;
  animation-duration: 0.25s;
  animation-name: customModalBackdrop-in;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(#{$zIndex} + 10);
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.contentWrapper {
  width: auto;
  min-width: 300px;
  animation-duration: 0.25s;
  animation-name: customModal_dialog-in;

  &.sm {
    max-width: 500px;
    margin: 8px auto;
    @include modal-middle;
  }

  &.md {
    margin: 25px auto;
    max-width: 650px;
    @include modal-middle;
  }

  &.lg {
    margin: 25px auto;
    max-width: 800px;
    margin-top: 100px;
  }

  &.xl {
    margin: 15px auto;
    max-width: 1000px;
    margin-top: 60px;
  }

  &.full {
    max-width: 100%;
    margin: 25px;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}

.header {
  color: white;
  font-size: 17px;
  background-color: $blue-color;
  padding: 14px 15px;
  font-family: 'Lato';
  display: flex;
  align-items: center;

  &.error {
    background-color: $red-color;
  }
}

.title {
  flex: 1 0 0;
  margin-right: 5px;
  min-height: 20px;
  font-family: Lato-Bold;
}

.closeIcon {
  cursor: pointer;
}

.body {
  padding: 15px;
}

.footer {
  text-align: right;
  padding: 15px;
  border-top: 1px solid $gray-color-4;
}

@keyframes customModal_dialog-in {
  from {
    opacity: 0;
    transform: translateY(-25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes customModal_dialog-middle-in {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }
  to {
    opacity: 1;
    transform: translateY(-25%);
  }
}

@keyframes customModalBackdrop-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}
