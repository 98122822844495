$color: var(--color);
$size: calc(var(--size) * 1px); // convert num to px

.loader {
  display: inline-flex;
  padding: 5px;
}

.dot {
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $color;
  color: $color;
  margin-left: calc(#{$size} + 3px);
}

.dot:nth-child(1) {
  animation: dotFlashing 0.5s infinite alternate;
  animation-delay: 0s;
  margin-left: 0;
}

.dot:nth-child(2) {
  animation: dotFlashing 0.5s infinite linear alternate;
  animation-delay: 0.25s;
}

.dot:nth-child(3) {
  animation: dotFlashing 0.5s infinite alternate;
  animation-delay: 0.5s;
}

@keyframes dotFlashing {
  0% {
    background-color: $color;
    opacity: 1;
  }
  50%,
  100% {
    background-color: $color;
    opacity: 0.3;
  }
}
