@import '../../../dependencies/scss/variables.scss';

$leftPadding: 110px;
$rightPadding: 20px;
$padding: $leftPadding + $rightPadding;

.loginTemplate {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  &_container {
    display: flex;
    min-height: 100%;
    & > div {
      width: 50%;
    }
  }
  &_logo {
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px;
  }
  &_billboard {
    &--img {
      background-image: url('Assets/background.svg');
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &--text {
      width: calc(50% - 220px);
      position: fixed;
      top: calc(50% - 115px);
      padding: 0 $rightPadding 0 $leftPadding;
      color: white;
      font-size: 17px;
      font-family: Lato;
    }
    &--services {
      font-family: Lato-Bold;
      font-size: 18px;
      opacity: 0.8;
      padding-bottom: 8px;
    }
    &--alaka {
      padding-bottom: 48px;
      font-family: Lato;
      font-size: 48px;
    }
    &--description {
      padding-bottom: 48px;
      font-size: 18px;
      line-height: 1.3;
    }
    &--team {
      font-size: 14px;
      opacity: 0.8;
    }
  }
  &_content {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.formWidth {
  max-width: 350px;
  width: 100%;
}
.authLink {
  color: $blue-color;
  font-size: 13px;
  font-family: 'Lato';
  &:hover,
  &:focus {
    color: $blue-color;
  }
  &_bolder {
    @extend .authLink;
    font-family: 'Lato';
  }
}
.authForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &_header {
    font-family: Lato;
    text-align: center;
    font-size: 32px;
    margin-bottom: 32px;
  }
  &_subHeader {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: Lato-Bold;
  }
  &_label {
    margin-bottom: 20px;
    text-align: center;
    & > div:first-of-type {
      margin-bottom: 10px;
    }
  }
  &_input {
    margin-bottom: 20px;
    @extend .formWidth;
  }
  &_link {
    @extend .authLink;
    padding-top: 10px;
    text-align: right;
    @extend .formWidth;
  }
  &_buttons {
    @extend .formWidth;
  }
  &_button {
    margin: 30px 0;
    display: flex;
    justify-content: center;
  }
  &_signUp {
    font-size: 13px;
    text-align: center;
    padding-top: 10px;
  }
  &_statement {
    font-size: 13px;
    // color: $gray-color-6;
    // font-family: Lato;
    max-width: 280px;
    text-align: center;
    padding-top: 10px;
    & > a {
      color: $blue-color;
      font-family: Lato;
      &:focus,
      &:hover {
        color: $blue-color;
      }
    }
  }
}

@media (max-width: 1150px) and (min-width: 651px) {
  .loginTemplate_billboard--text {
    width: calc(50% - 90px);
    padding: 0 30px 0 30px;
  }
}
@media (max-width: 650px) {
  .loginTemplate_billboard {
    display: none;
  }
  .loginTemplate_container > div {
    width: 100%;
  }
}
