@import '../../../dependencies/scss/variables.scss';

.container {
  display: flex;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Lato';
  height: 28px;
  box-sizing: border-box;

  &.opened > .label {
    border-bottom-left-radius: 0;
  }
  &.opened > .control {
    border-bottom-right-radius: 0;
    background-color: $blue-color-6;
  }

  &.secondary:not(.disabled) > .label {
    background-color: #fff;
    border: solid thin $blue-color-5;
    color: $blue-color-5;

    &:hover {
      background-color: $blue-color-1;
    }
  }
  &.secondary:not(.disabled) > .control {
    border: solid thin $blue-color-5;
    border-left: none;
    background-color: #fff;

    &:hover {
      background-color: $blue-color-1;
    }
  }
  &.secondary.opened > .control {
    background-color: $blue-color-1;
  }

  &.loading {
    background-color: $blue-color-5;
    border-radius: 3px;
  }
  &.loading.secondary {
    background-color: #fff;
    border-radius: 3px;
    border: solid thin $blue-color-5;
  }
  &.loading > .label {
    cursor: initial;
    transition: none;
    border-right: none;

    &:hover {
      background-color: $blue-color-5;
    }
  }
  &.loading.secondary > .label {
    background-color: #fff;
    border: none;

    &:hover {
      background-color: #fff;
    }
  }

  &.disabled > .label,
  &.disabled > .control {
    position: relative;
    cursor: initial;
    color: #fff;
    background-color: $gray-color-5;
    border-color: $gray-color-5;
  }
  &.disabled > .label:after {
    content: '';
    position: absolute;
    top: 1px;
    right: 0;
    width: 1px;
    height: calc(100% - 2px);
    background-color: #fff;
  }
}

.label {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 2px 12px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  transition: background-color 150ms linear;
  background-color: $blue-color-5;
  border-right: solid thin $blue-color-6;
  color: #fff;

  .button:not(.disabled) &:hover {
    background-color: $blue-color-6;
  }
}

.control {
  align-self: stretch;
  padding: 2px;
  transition: background-color 150ms linear;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: $blue-color-5;
  color: #fff;

  & > svg {
    transform: translateY(2px);
  }
  .button:not(.disabled) &:hover {
    background-color: $blue-color-6;
  }
}

.menu {
  min-width: 100%;
  white-space: nowrap;
  padding: 4px 0;
  border-top: none;
  border-top-left-radius: 0;
}

.item {
  padding: 8px 12px;
  transition: background-color 250ms linear;

  &:hover {
    background-color: $gray-color-3;
  }
}

.loader {
  position: absolute;
  display: inline-flex;
  left: 50%;
  transform: translateX(-50%);
}

.hidden {
  visibility: hidden;
}
