@import '../../../dependencies/scss/variables.scss';

.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $gray-color-4;
  margin-top: 16px;

  &.bordered {
    border-left: 3px solid $blue-color-4;
  }

  &:not(.bordered) {
    padding-left: 18px;
  }
}

.bannerLeft {
  display: flex;
  margin-right: 20px;
}

.link {
  font-size: inherit;
}

.reportLinkWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.reportLink {
  display: flex;
  align-items: center;
}

.upload {
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.2;
}

.error {
  margin-top: 3px;
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $red-color;
}

.uploadLegalNote {
  margin-top: 8px;
  color: $gray-color-6;
  font-family: 'Lato';
  font-size: 12px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mri {
  padding: 30px 20px;
  background-color: $gray-color-2;
}

.mriTitle {
  font-family: 'Lato';
  font-size: 15px;
  margin-bottom: 20px;
}
