.title {
  font-family: 'Lato';
}

.duration {
  align-self: center;
  justify-self: flex-end;
  font-family: 'Lato';
  font-size: 13px;
}

.dateText {
  font-size: 13px;
  font-family: 'Lato-Light';
}

.description {
  margin-top: 5px;
  font-size: 14px;
  font-family: 'Lato-Light';
}
