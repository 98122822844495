@import '../../../dependencies/scss/variables.scss';

@mixin step($color, $secondColor) {
  background-color: $color;
  svg {
    color: $secondColor !important;
  }
}

@mixin progressLineStyling($color) {
  &::-moz-progress-bar {
    background: $color;
    transition: all 200ms ease-out;
  }
  &::-webkit-progress-bar {
    transition: all 200ms ease-out;
  }
  &::-webkit-progress-value {
    transition: all 200ms ease-out;
    background: $color;
  }
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid transparent;

  &.blue {
    @include step($blue-color-1, $blue-color-8);
  }
  &.green {
    @include step($green-color-1, $green-color-7);
  }
  &.yellow {
    @include step($yellow-color-2, $yellow-color-9);
  }
  &.red {
    @include step($red-color-1, $red-color-7);
  }
  &.gray {
    @include step($gray-color-3, $gray-color-8);
  }
}

.line {
  height: 2px;
  width: 100%;
  position: absolute;
  top: 7px;

  &.blue {
    @include progressLineStyling($blue-color-1);
  }
  &.green {
    @include progressLineStyling($green-color-1);
  }
  &.yellow {
    @include progressLineStyling($yellow-color-2);
  }
  &.red {
    @include progressLineStyling($red-color-1);
  }
  &.gray {
    @include progressLineStyling($gray-color-3);
  }
}

.progress {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.progressCover {
  content: '';
  position: absolute;
  width: 50%;
  height: 16px;
  background-color: white;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 80px;

  &:first-of-type::before {
    @extend .progressCover;
    transform: translate(-50%);
  }

  &:last-of-type::before {
    @extend .progressCover;
    transform: translate(50%);
  }
}

.label {
  margin-top: 8px;
  color: $gray-color-7;
  font-family: Lato-Bold;
  font-size: 12px;
  text-align: center;
}

.pending {
  background-color: white;
  border: 1px dashed $gray-color-5;
}

.activeText {
  color: initial;
}

.circleContainer {
  background-color: white;
  z-index: 1;
}

.status {
  max-width: 500px;
  margin-top: 20px;
}

.statusTitle {
  margin-bottom: 8px;
}
