@import '../../../dependencies/scss/colorMixins.scss';

.container {
  display: flex;
  align-items: center;
}

.icon {
  position: relative;
  display: inline-flex;
  @include colors;
  background-color: transparent;
}
