@import '../../../../../dependencies/scss/variables.scss';

.table {
  position: relative;

  &.scrollable::after {
    content: '';
    position: absolute;
    top: 0;
    left: 465px;
    width: 1px;
    height: 100%;
    border-right: solid thin $gray-color-4;
    box-shadow: 2px 0 3px $gray-color-4;
    z-index: 100;
  }

  &.scrollable .ratesSimulation {
    max-width: calc(var(--tableWidth) * 1px);
    position: sticky;
    left: 0;
    z-index: 150;
    background-color: #fff;
  }

  &.scrollable .row {
    grid-template-columns: 470px 1fr;
    column-gap: 5px;
  }

  &.scrollable .fixedColumn {
    padding-right: 15px;
  }
}

.row {
  position: relative;
  display: grid;
  grid-template-columns: 445px 1fr;
  column-gap: 15px;
  padding: 0;
  padding-right: 4px;

  &.narrow > .scrollableColumn {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.fixedColumn {
  position: sticky;
  left: 0;
  height: 100%;
  padding-left: 12px;
  display: grid;
  grid-template-columns: 60px 1fr 95px 75px;
  column-gap: 15px;
  z-index: 5;
  background-color: #fff;

  &.proposal {
    grid-template-columns: 1fr 95px 95px;
  }
}

.fixedCandidateColumn {
  @extend .fixedColumn;
  align-items: center;
  background-color: $gray-color-1;

  &.highlighted {
    background-color: $secondary-hover-color;
  }
}

.scrollableColumn {
  display: grid;
  grid-template-columns: 1fr 30px;
  column-gap: 15px;
  padding-left: 5px;
}

.scrollableCandidateColumn {
  @extend .scrollableColumn;
  align-items: center;
  padding: 16px 0;
  padding-left: 5px;
}

.head {
  margin: 0;
  overflow: hidden;
  padding-left: 0;
}

.body {
  max-height: 235px;
}

.bodyHeight {
  max-height: 500px;
}

.rates {
  min-width: calc(var(--numberOfYears) * 80px);
}

.nameBox {
  overflow: hidden;
}

.nameWrapper {
  display: flex;
}
