@import '../../../dependencies/scss/variables.scss';

.chip {
  color: white;
  font-size: 11px;
  padding: 3px 8px;
  border-radius: 15px;
  font-family: 'Lato';
  &_noteWrapper {
    display: inline-block;
  }
  &_note {
    display: flex;
    align-items: center;
    line-height: 1.1;
    & > svg {
      padding-left: 5px;
      box-sizing: content-box;
    }
  }
  &_noteContent {
    padding: 20px;
    max-width: 300px;
  }
  &--small {
    font-size: 9px;
    padding: 2px 6px;
    border-radius: 15px;
    display: flex;
    align-items: center;
  }
  &_label {
    padding-left: 2px;
  }
  &-bg- {
    &blue {
      background-color: $blue-color;
    }
    &green {
      background-color: $green-color;
    }
    &yellow {
      background-color: $yellow-color;
    }
    &gray {
      background-color: $gray-color-6;
    }
    &red {
      background-color: $red-color;
    }
    &white {
      background-color: white;
    }
    &lightGray {
      background-color: $gray-color-4;
      color: $gray-color-8 !important;
    }
  }
}

.smallChip {
  font-size: 9px;
  padding: 2px 6px;
  font-family: 'Lato-Bold';
  letter-spacing: 1px;
  @extend .chip;
}

.text- {
  &blue {
    color: $blue-color;
  }
  &green {
    color: $green-color;
  }
  &yellow {
    color: $yellow-color;
  }
  &gray {
    color: $gray-color-6;
  }
  &red {
    color: $red-color;
  }
  &white {
    color: white;
  }
}
