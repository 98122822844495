.avatarList {
  margin-left: 6px;
  display: flex;
}

.avatar {
  display: inline-block;
  margin-left: -7px;
  z-index: 10;
}
