.statusFlowTitle {
  font-size: 15px;
  margin-bottom: 20px;
}

.statusFlowContent {
  min-width: 450px;
  padding: 20px;
}

.chipContent {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.icon {
  display: inline-flex;
}
