.container {
  font-family: "Lato-Light";
}

h1.container {
  font-size: 25px;
}

h2.container {
  font-size: 20px;
}

h3.container {
  font-size: 16px;
}
