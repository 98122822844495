@import '../../../../dependencies/scss/variables.scss';

.container {
  max-width: 1200px;
}

.icon {
  display: inline-flex;
  color: $gray-color-6;
}

.sortTitle {
  font-family: 'Lato-Italic';
  color: $gray-color-6;
}
