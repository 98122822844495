.container {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #e6e6e6;
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin-left: 8px;
}

.filenameWrapper {
  display: flex;
  align-items: center;
}

.tags {
  margin-left: 8px;
  flex-shrink: 0;
}

.tag {
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }
}

.filename {
  overflow: hidden;
  font-family: 'Lato';
}

.description {
  margin-top: 2px;
  font-family: 'Lato-Italic';
  font-size: 13px;
}

.actions {
  margin-left: 16px;
  display: flex;
}