@import '~@styles/colors';

.container {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid $gray;
  border-left: 3px solid;

  &.blue {
    border-left-color: $blue;
    & > .icon {
      background-color: $blue-lightest;
    }
  }
  &.yellow {
    border-left-color: $yellow;
    & > .icon {
      background-color: $yellow-lightest;
    }
  }
  &.red {
    border-left-color: $red;
    & > .icon {
      background-color: $red-lightest;
    }
  }
  &.green {
    border-left-color: $green;
    & > .icon {
      background-color: $green-lightest;
    }
  }
}

.icon {
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  margin-left: 16px;
}
