@import '../../../../../dependencies/scss/variables.scss';

.document {
  display: flex;
  align-items: center;
  overflow: hidden;
  font-family: 'Lato';
}

.icon {
  margin-right: 5px;

  &.disabled {
    opacity: 0.5;
    filter: grayscale(100%);
  }
}

.bordered {
  display: flex;
  padding: 14px 10px;
  border: solid thin $gray-color-4;
  transition: background-color 300ms linear;

  &:hover {
    background-color: $gray-color-1;
  }
}

.filename {
  overflow: hidden;
}
