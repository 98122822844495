@import '../../../dependencies/scss/variables.scss';

.backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1300;
  position: absolute;
}

.content {
  background-color: #fff;
  z-index: 10;
  border: solid thin $gray-color-4;
  border-radius: 3px;
  box-shadow: 8px 7px 17px 0px rgba(#000, 0.07), 1px 1px 6px 0px rgba(#000, 0.04),
    5px 6px 6px 2px rgba(#000, 0.04);
}
