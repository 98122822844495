@import '../../../../../dependencies/scss/variables.scss';

.wrapper {
  position: relative;
  color: $gray-color-4;

  &.highlighted {
    color: $gray-color-5;
  }
  &.disabled > .doneIcon {
    color: $gray-color-5;
  }
}

.doneIcon {
  position: absolute;
  top: 2px;
  right: -5px;
  color: $green-color;
}
