@import '../../../dependencies/scss/variables.scss';

$width: 800px;

.pageBlock {
  max-width: $width;
  margin: 0 auto;
  padding: 0 20px;
  text-align: justify;
}
.legalPage {
  &_tabsContainer {
    padding-top: 20px;
    background-color: $gray-color-1;
    border-bottom: 1px solid $gray-color-4;
    position: sticky;
    top: $header-hight;
  }
  &_tabs {
    max-width: 800px;
    margin: 0 auto;
  }
  &_content {
    @extend .pageBlock;
    padding: 60px 20px;
  }
}

.processingTable {
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  grid-auto-flow: column;
  border-bottom: 1px solid $gray-color-4;
  border-right: 1px solid $gray-color-4;
  margin: 10px 0;
  &_item {
    padding: 10px;
    border-left: 1px solid $gray-color-4;
    border-top: 1px solid $gray-color-4;
  }
}

.genericCell {
  padding: 5px;
  flex: 1;
}

.technicalMeasuresTable {
  margin: 20px 0 10px 0;
  &_description {
    font-family: Lato-Italic;
    font-size: 14px;
    color: $gray-color-8;
    padding-bottom: 8px;
  }
  &_header {
    display: flex;
    background-color: $gray-color-4;
    font-family: Lato-Bold;
  }
  &_row {
    display: flex;
    margin-top: 2px;
    &_gray {
      background-color: $gray-color-3;
    }
    &_content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  &_column {
    &_first {
      @extend .genericCell;
      background-color: $gray-color-4;
      font-family: Lato-Bold;
      padding: 5px 15px;
      max-width: 200px;
      text-align: left;
    }
    &_second {
      @extend .genericCell;
      padding-left: 15px;
      max-width: 30px;
    }
    &_third {
      padding: 5px 15px;
      flex: 1;
    }
  }
}

.personalDataTable {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  &_row {
    display: flex;
    padding: 5px;
    &:nth-child(odd) {
      background-color: $gray-color-3;
    }
    &:first-child {
      font-family: Lato-Bold;
      background-color: $gray-color-4;
    }
    &:last-child {
      border-bottom: 1px solid $gray-color-4;
    }
    &_first {
      flex: 1;
      font-family: Lato-Bold;
      text-align: left;
    }
    &_second {
      flex: 2;
      padding: 0 15px;
      line-height: 1.2;
    }
    &_third {
      flex: 1;
      line-height: 1.2;
    }
  }
}

.personalDataTable {
  &_column {
    &_second {
      max-width: inherit;
    }
  }
  &_row {
    &_content {
      flex-direction: row;
      line-height: 1.5;
      text-align: left;
    }
  }
}

.lastUpdateDate {
  font-size: 13px;
  font-family: 'Lato-Bold';
  color: $gray-color-8;
}
