@import '../../../../../dependencies/scss/variables.scss';

.positionCandidate {
  display: flex;
  transition: all 0.2s ease-out;
  &:hover:not(:focus-within) {
    box-shadow: 0 0 5px $gray-color-6;
  }
  & * {
    box-sizing: border-box;
  }
  &_summary {
    padding: 15px;
    width: 280px;
    min-width: 280px;
    border-top: 1px solid $gray-color-4;
    background-color: $gray-color-2;
  }
  &_rates {
    flex: 1 0 0;
    &_type {
      width: 170px;
      min-width: 170px;
    }
  }
  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &_title {
      display: flex;
      align-items: center;
    }
    &_name {
      margin-left: 8px;
      font-size: 15px;
      font-family: 'Lato-Bold';
    }
    &_company {
      display: flex;
      margin-top: 2px;
      margin-bottom: 3px;
      font-size: 13px;
      font-family: 'Lato-Italic';
    }
    &_statuses {
      display: flex;
      align-items: center;
    }
  }
  &_date {
    display: flex;
    color: $gray-color-6;
    font-size: 11px;
    padding-top: 2px;
    font-family: Lato;
    strong {
      padding: 0 2px;
      font-family: Lato-Bold;
    }
  }
  &-dark &_summary,
  &-dark .rateTable-disabled {
    background-color: $gray-color-2;
  }

  & .rateTable {
    height: 100%;
  }
  & .rateTable_input:last-child,
  & .rateTable_cell:last-child {
    border-right: none;
  }
}
