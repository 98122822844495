@import '@styles/spacing';
@import '@styles/colors';

.input {
  padding-right: $spacing-1;
}

.icons {
  display: contents;

  & button {
    padding: 5px;
    margin-right: 0;
  }
}

.listContainer {
  font-size: 14px;
  font-family: Lato-Light;
  line-height: 1.4;
  background-color: white;
  border: solid thin $gray;
  border-top: none;
  overflow: auto;

  & > :global(.MuiAutocomplete-listbox) {
    padding: $spacing-2 0;
  }
}

.chip {
  font-family: Lato-Bold;
  font-size: 12px;
  background-color: $blue-lightest;
  color: $blue-darkest;

  &:global(.Mui-focusVisible) {
    background-color: $gray-light;
  }
}

.listItem {
  padding-left: $spacing-3 !important;
  padding-right: $spacing-3 !important;
}

.listItemLabel {
  margin-left: $spacing-2;
}