@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

$aside-width: 280px;

.container {
  height: 100%;
}

.aside {
  position: fixed;
  top: $header-hight;
  bottom: 0;
  width: $aside-width;
  border-right: 1px solid $gray-color-4;
  background-color: $gray-color-1;
  overflow-y: auto;
}

.body {
  position: absolute;
  left: $aside-width + 1;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.navigation {
  position: sticky;
  top: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  padding-top: 40px;
  border-bottom: 1px solid $gray-color-4;
}

.navigationContent {
  position: relative;
  bottom: 5px;
  margin-left: 20px;
}

.content {
  flex: 1;
  padding: 30px 50px;
  background-color: $gray-color-1;
  overflow-y: auto;
}
