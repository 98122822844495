@import '../../../dependencies/scss/variables.scss';

.container {
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.candidateName {
  font-size: 24px;
  font-family: Lato;
}

.position {
  font-size: 12px;
  color: $gray-color-7;
  font-family: Lato-Bold;
  display: flex;
  padding-top: 4px;
}

.scoreContainer {
  padding: 24px 0;
}

.match {
  &Container {
    font-size: 20px;
    font-family: Lato;
    display: flex;
    padding: 8px 0;
  }
  &Icon {
    padding-right: 8px;
  }
  &Label {
    display: flex;
    align-items: center;
  }
}

.chipContainer {
  margin-top: 8px;
}

.mainContent {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: stretch;
  justify-content: center;
}

.footer {
  font-size: 12px;
  font-family: 'Lato';
  color: $gray-color-7;
  text-align: center;
  line-height: 1.2;
  margin-top: 24px;
}
