@import '../../../dependencies/scss/variables.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 48px;
  border-top: 5px solid $blue-color;

  &.success {
    border-color: $green-color;
  }
  &.success > .icon {
    color: $green-color-1;
  }

  &.error {
    border-color: $red-color;
  }
  &.error > .icon {
    color: $red-color-1;
  }
}

.close {
  position: absolute;
  top: 25px;
  right: 20px;
}

.icon {
  margin-bottom: 32px;
}

.title {
  font-family: 'Lato';
  font-size: 20px;
  margin-bottom: 35px;
}

.content {
  line-height: 1.3;
  font-size: 16px;
}

.buttons {
  display: flex;
  margin-top: 32px;
}
