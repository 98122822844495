@import '../../../../../dependencies/scss/variables.scss';

.otherOptions {
  display: flex;
  margin-top: 18px;
  transition: all 200ms ease-out;
  & > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid $gray-color-4;
    border-top: 2px solid $gray-color-4;
    background-color: $gray-color-1;
    padding: 25px 10px 15px 10px;
    font-size: 11px;
  }
  &_option {
    display: flex;
    justify-content: space-around;
    flex: 1;
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 1;
      flex: 1;
    }
  }
  &_line {
    height: 1px;
    background-color: $gray-color-4;
    position: absolute;
    width: 250px;
    top: 219px;
    z-index: 0;
  }
}

@mixin arrowAfter() {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 12px solid $gray-color-2;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
@mixin arrowBefore($color) {
  content: '';
  position: absolute;
  right: -12px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 12px solid $color;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
@mixin arrow($color, $secondColor) {
  color: $secondColor;
  font-family: Lato;
  background-color: $color;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-right: 12px;
  padding: 0 16px;
  text-align: center;
  flex: 1;
}

@mixin fullArrow($color, $secondColor) {
  &.arrow-first {
    @include arrow($color, $secondColor);
    &:before {
      @include arrowBefore($color);
    }
  }
  &.arrow-last {
    @include arrow($color, $secondColor);
    &:after {
      @include arrowAfter();
    }
  }
  &.arrow {
    @include arrow($color, $secondColor);
    &:after {
      @include arrowAfter();
    }
    &:before {
      @include arrowBefore($color);
    }
  }
}

.arrowBasic {
  @include fullArrow($gray-color-6, white);
  &.green {
    @include fullArrow($green-color, white);
  }
  &.blue {
    @include fullArrow($blue-color, white);
  }
  &.grayLight {
    @include fullArrow($gray-color-4, $gray-color-6);
  }
  &.red {
    @include fullArrow($red-color, white);
  }
}
@mixin arrowRole($color, $secondColor) {
  margin-right: 12px;
  margin-bottom: 5px;
  background-color: darken($color, 5%);
  color: $secondColor;
  text-align: center;
  padding: 2px;
  font-family: Lato;
}
.arrow {
  &_role {
    @include arrowRole($gray-color-6, white);
    &.green {
      @include arrowRole($green-color, white);
    }
    &.blue {
      @include arrowRole($blue-color, white);
    }
    &.grayLight {
      @include arrowRole($gray-color-4, $gray-color-6);
      background-color: $gray-color-4;
    }
    &.red {
      @include arrowRole($red-color, white);
    }
  }
}
.arrowContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
