@import '../../../../dependencies/scss/variables.scss';

.rfqRates {
  display: flex;
  overflow-x: auto;
  & * {
    box-sizing: border-box;
  }
  &_allowance {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 12px 15px;
    width: 280px;
    min-width: 280px;
    background-color: $gray-color-2;
    border: 1px solid $gray-color-4;
    border-right: none;
    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
    }
    &_label {
      font-size: 13px;
      font-family: Lato-Bold;
    }
    &_value {
      font-size: 13px;
    }
    &_input {
      padding: 6px 5px;
      font-size: 13px;
      width: 50px;
      background: #fff;
    }
    &_emptyInput {
      width: 50px;
      padding: 7px 5px;
      font-size: 14px;
      text-align: right;
      border: 1px solid #e6e6e6;
    }
  }
  &_rates {
    flex: 1 0 0;
    &_type {
      width: 170px;
      min-width: 170px;
    }
  }
}
