.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}

.count {
  display: inline-flex;
  margin-left: 8px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  margin-top: 24px;
}

.row {
  grid-template-columns: minmax(250px, 1fr) minmax(200px, 1.5fr) 200px 180px 120px 100px;
}

.openImportLink {
  margin-right: 8px;
}

.alert {
  margin-top: 24px;
}