@import '../../../dependencies/scss/variables.scss';

.loader {
  padding: 50px;
}

.content {
  height: 100%;
  overflow-y: auto;
  background-color: $gray-color-1;
}
.header {
  padding: 50px;
  padding-bottom: 20px;
  background-color: white;
  border-bottom: 1px solid $gray-color-3;
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.title {
  font-family: 'Lato-Light';
  font-size: 25px;
  margin-right: 20px;
}
.subtitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.subtitle {
  font-family: 'Lato';
}
.dates {
  color: $gray-color-8;
  margin-bottom: 20px;
}

.card {
  padding: 30px 45px;
  background-color: white;
  border: 1px solid $gray-color-4;
}
.infoText {
  font-family: 'Lato-Italic';
  color: $gray-color-7;
  font-size: 13px;
}
.candidateGroup {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.candidateName {
  font-family: 'Lato';
  margin: 0 5px;
  font-size: 24px;
}
.candidateRate {
  margin-top: 40px;
}
.candidateDetails {
  font-family: 'Lato';
  line-height: 1.3;
  margin-top: 15px;
}
.profession {
  font-family: 'Lato-bold';
  font-size: 16px;
  margin-right: 15px;
}
.professionGroup {
  display: inline-flex;
}

.banners {
  display: flex;
  flex: 1;
  margin-right: 20px;
}
.bannerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
}
.banner {
  flex: 1;
  align-items: center !important;
}
.bannerTitle {
  font-family: 'Lato';
  margin-bottom: 15px;
  font-size: 16px;
}
.bannerReportLink {
  font-size: 15px;
}
.bannerSubtitle {
  font-family: 'Lato';
  font-size: 13px;
  margin-top: 2px;
  color: $gray-color-7;
}

.clearance {
  font-family: 'Lato-Bold';
  color: $blue-color-8;
  font-size: 12px;
  margin-right: 5px;
}
.clearanceIcon {
  margin-right: -5px;
}
.clearanceNote {
  font-family: 'Lato';
  margin-top: 5px;
}
.popoverBody {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  & p {
    margin-bottom: 5px;
    font-family: 'Lato';
  }
}

.infoIcon {
  display: inline-flex;
}
.candidateIcon {
  background-color: $blue-color-5;
  color: white !important;
  border-radius: 50%;
  padding: 5px;
}
.contact {
  margin-top: 30px;
  font-family: 'Lato';
  text-align: right;
}
