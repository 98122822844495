@import '../../../../dependencies/scss/variables.scss';

.item {
  display: grid;
  grid-template-columns: 1fr 180px;
  column-gap: 20px;
  border-top: solid thin $gray-color-4;
  padding: 10px 0;

  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}
