@import '../../../dependencies/scss/variables.scss';

.responsiveTable {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  width: 100%;
  * {
    box-sizing: border-box;
  }
  &_item {
    display: table-cell;
    vertical-align: middle;
    padding: 12px 5px;
  }
  &_row {
    display: table-row;
    &:last-child {
      border-bottom: solid thin $gray-color-4;
    }
  }
  &_head {
    font-family: Lato-Bold;
    font-size: 11px;
    color: $gray-color-6;
    text-transform: uppercase;
  }
  &_cell {
    border-right: solid thin $gray-color-4;
    &:last-child {
      border-right: none;
    }
  }
  &--striped {
    .responsiveTable_row:nth-child(even) {
      background-color: $gray-color-3;
    }
  }
  &--bordered {
    .responsiveTable_item {
      border: solid thin $gray-color-4;
    }
  }
}
