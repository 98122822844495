@import '../../../dependencies/scss/variables.scss';
$menu-padding: 15px;

@mixin menuColors($color, $dark-color) {
  height: 100%;
  background-color: $color;
  & .menuItem--active {
    background-color: $dark-color;
    & .menuItem_highlight {
      background-color: $blue-color;
    }
  }
  & .menuItem_chip {
    color: $dark-color;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
  width: $menu-width;
  transition: width 0.3s ease 0s;
  z-index: 8000;
  position: sticky;
  &--atrament {
    @include menuColors($atrament-color, $atrament-dark-color);
  }
  &--brownish {
    @include menuColors($brownish-color, $brownish-dark-color);
  }
  &--manager {
    @include menuColors($manager-color, $manager-dark-color);
  }
  &.wider {
    width: $menu-big-width;
    transition: width 0.3s ease 0s;
  }
  &_bottomButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $menu-width;
    padding: 5px 0;
    cursor: pointer;
    &.active {
      width: $menu-big-width;
      align-items: flex-start;
      transition: width 0.3s ease 0s;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.menuItem {
  padding: 10px $menu-padding;
  cursor: pointer;
  @extend .flex-row;
  align-items: center;
  transition: all 0.3s ease 0s;
  &.small {
    padding-right: 20px;
    justify-content: center;
    flex: 1;
    transition: all 0.3s ease 0s;
  }
  & .small {
    display: none;
    transition: all 0.3s ease 0s;
  }
  &_highlight {
    width: 5px;
    min-width: 5px;
    height: 50px;
  }
  &_label {
    padding: 0 14px;
    font-size: 17px;
    min-width: 140px;
  }
  &_chip {
    font-size: 11px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    padding: 2px 5px;
    font-family: 'Lato';
  }
}

.group {
  &_label {
    padding: 18px 5px 4px 12px;
    font-size: 11px;
    font-family: 'Lato-Bold';
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    transition: all 0.3s ease 0s;
    &.small {
      font-size: 9px;
      padding: 6px 0 6px 0;
      display: flex;
      justify-content: center;
    }
  }
}
