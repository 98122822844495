@import '../../../dependencies/scss/variables.scss';

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: -47px;
  padding: 0 100px;
  z-index: 1;
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  min-width: 120px;

  &.active > .step {
    background-color: $blue-color-1;
    color: $blue-color-8;
    border: 1px solid $blue-color-1;
  }
  &.active > .label {
    color: $blue-color-8;
  }
  &.error > .step {
    position: relative;
    color: $red-color;
    border: 1px solid $red-color;
  }
  &.error > .step:after {
    content: '!';
    position: absolute;
    right: -4px;
    top: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: white;
    background-color: $red-color;
    border-radius: 50%;
    font-size: 14px;
    font-family: 'Lato-Bold';
  }
  &.error > .label {
    color: $red-color;
  }
}

.step {
  font-family: Lato;
  font-size: 24px;
  border-radius: 50%;
  border: 2px dashed $gray-color-4;
  background-color: #fff;
  color: $gray-color-6;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: 12px;
  text-transform: uppercase;
  font-family: Lato-Bold;
  color: $gray-color-6;
  margin-top: 8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.content {
  padding: 100px 32px 40px;
  border: 1px solid $gray-color-4;

  &.full {
    padding: 0;
    border: none;
  }
  &.error {
    border-color: $red-color;
  }
}
