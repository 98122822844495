@mixin rightTriangle($top, $bottom, $left, $color) {
  width: 0;
  height: 0;
  border-top: $top solid transparent;
  border-bottom: $bottom solid transparent;
  border-left: $left solid $color;
}
@mixin highlightWord($color, $backgroundColor) {
  background-color: $backgroundColor;
  color: $color;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
@mixin placeholder() {
  color: $gray-color-6;
  font-family: 'Lato-Italic';
  font-size: 13px;
  opacity: 1;
}
@mixin description() {
  font-size: 13px;
  color: $gray-color-6;
  font-family: 'Lato';
  // margin-top: -4px;
}

@mixin subtitle() {
  font-family: 'Lato-Bold';
  font-size: 11px;
  color: $gray-color-6;
  text-transform: uppercase;
}

@mixin truncatedText() {
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // Overflow: hidden crops some fonts. In out case the bottom parts of letters "g", "j" etc.
  // line-height: normal fix this problem
  line-height: normal;
}

@mixin cardTitle() {
  font-size: 17px;
  font-family: 'Lato-Bold';
  cursor: pointer;
}
@mixin dateLabel() {
  font-size: 11px;
  color: $gray-color-6;
  font-family: 'Lato';
}

@mixin hoverStyle() {
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.15);
}

@mixin grid($minMax) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($minMax, 1fr));
}
