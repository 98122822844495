@import '../../../../dependencies/scss/variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rfq {
  font-family: 'Lato';
  font-size: 12px;
  margin-bottom: 2px;
}

.title {
  font-size: 14px;
}

.evaluationMethod {
  flex-shrink: 0;
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $gray-color-7;
}

.publishedInfo {
  font-size: 14px;
  margin-top: 2px;
}

.publishedCount {
  font-family: 'Lato';
}

.positionDueDateInfo {
  color: $gray-color-7;
  font-family: 'Lato';
  font-size: 12px;
  margin-top: 8px;
}

.positionDueDate {
  font-family: 'Lato-Bold';
}

.positionDueDateInput,
.positionAutocomplete {
  max-width: 350px;
  min-width: 250px;
}

.targetRatesError {
  margin-top: 8px;
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $red-color;
}

.rateSimulationHeader {
  padding: 15px;
  background-color: $gray-color-2;
}

.disabledSimulation {
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $gray-color-6;
  margin-top: 24px;
}
