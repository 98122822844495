@import '../../../dependencies/scss/variables.scss';

.user {
  display: flex;
  align-items: center;
  padding: 32px 16px;
  transition: all 0.3s ease 0s;
  &_text {
    transition: all 0.3s ease 0s;
    min-width: 140px;
  }
  &_logo {
    width: 42px;
    margin-right: 15px;
  }
  &_logoContainer {
    transition: all 0.3s ease 0s;
  }
  &_name {
    font-family: 'Lato';
    font-size: 17px;
    margin-bottom: 2px;
  }
  &_company {
    font-family: 'Lato-Italic';
    color: $gray-color-6;
    font-size: 13px;
    padding-top: 2px;
  }
}
