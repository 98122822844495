
  
@import '../../../dependencies/scss/variables.scss';

.textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $gray-color-4;
  resize: none;
  padding: 10px;
  font-size: 13px;
  line-height: 16px;
  font-family: 'Lato-Light';

  &.disabled {
    background-color: $gray-color-2;
  }
  &.error {
    border-color: $red-color;
  }
}