@import '../../../dependencies/scss/variables.scss';
.pageHeader {
  padding-bottom: 15px;
  &_firstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.filters {
  display: flex;
  flex-wrap: wrap;
  &_clear {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: underline;
    font-size: 13px;
    font-family: 'Lato-Italic';
    color: $gray-color-6;
  }
}
