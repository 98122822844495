@import '../../../dependencies/scss/variables.scss';

.container {
  display: flex;
}

.panel {
  padding: 64px 48px;
}

.leftPanel {
  width: 30%;
  min-width: 250px;
  background: $gray-color-1;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: 26px;
  margin-bottom: 32px;
}

.subtitle {
  font-family: 'Lato';
  font-size: 16px;
}

.description {
  line-height: 1.3;
  font-size: 14px;
}

.mainDescription {
  @extend .description;
  text-align: justify;
}

.link {
  display: inline;
  font-family: 'Lato-Bold';
}

.groupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trackedInfoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.fullGrid {
  grid-column: 1/-1;
}
