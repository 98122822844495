@import '../../../../dependencies/scss/variables.scss';

.wrapper {
  display: block;
  width: 100%;
  overflow-x: auto;

  & > * {
    box-sizing: border-box;
  }
}

.table {
  display: table;
  width: 100%;
  max-width: 100%;
}

.grid {
  display: grid;
  column-gap: 24px;
  // score|candidate|status|rate|location|mri|lastUpdate|submit
  grid-template-columns: 40px minmax(100px, 1fr) 90px 90px minmax(100px, 1fr) 100px 130px 130px;
  padding: 12px 10px;
}

.row {
  @extend .grid;
  background-color: $gray-color-1;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: background-color;

  &:hover {
    background-color: $gray-color-2;
  }
}

.head {
  display: flex;
  align-items: flex-end;
  font-family: 'Lato-Bold';
  font-size: 11px;
  color: $gray-color-6;
  text-transform: uppercase;
  line-height: 1.2;

  &.right {
    text-align: right;
    justify-content: flex-end;
  }

  &.center {
    text-align: center;
    justify-content: center;
  }
}

.smaller {
  font-size: 14px;
}

.columnCell {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;

  &.right {
    align-items: flex-end;
  }
}

.cell {
  display: flex;
  overflow: hidden;
  align-items: center;
  font-size: 16px;

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}

.score {
  @extend .cell;
  justify-content: center;
  overflow: visible;
}

.availableFrom {
  font-size: 13px;
}

.locationIcon {
  display: inline-flex;
  position: relative;
  top: 1px;
  flex-shrink: 0;
  margin-right: 4px;
  color: $gray-color-6;
}

.noResults {
  color: $gray-color-8;
  margin: 10px 0;
}
