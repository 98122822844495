@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/flexMixins.scss';

.surveyCustom {
  display: flex;
  padding: 5px 0;
  &_boxGroup {
    display: flex;
  }
  &_label {
    @include flex(1, 0, 0);
  }
  &_radio {
    display: flex;
    width: 50px;
  }
  &_radioGroup {
    display: flex;
    align-items: center;
    @include flex(1, 0, 0);
    justify-content: flex-end;
  }
}
