@import '../../../../dependencies/scss/variables.scss';

$color: $red-color-1;
$textColor: $red-color-7;

$size: calc(var(--size) + 20);
$width: calc(#{$size}* 1px);
$fontSize: 13px;
$height: calc(#{$size}/ 1.732 * 1px);
$offset: calc(#{$height} / 2);
$border: calc(#{$width} / 2);
$triangleSide: calc(#{$size} * 0.72 * 1px);

@mixin colors($backgroundColor, $fontColor) {
  .hexagon {
    background-color: $backgroundColor;
    &:before {
      border-bottom-color: $backgroundColor;
    }
    &:after {
      border-top-color: $backgroundColor;
    }
  }
  .text {
    color: $fontColor;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato-Bold;
  position: relative;
  &.red {
    @include colors($red-color-1, $red-color-7);
  }
  &.green {
    @include colors($green-color-1, $green-color-7);
  }
  &.blue {
    @include colors($blue-color-1, $blue-color-8);
  }
  &.yellow {
    @include colors($yellow-color-2, $yellow-color-9);
  }
  &.gray {
    @include colors($gray-color-3, $gray-color-8);
  }
}

.text {
  font-family: Lato-Bold;
  font-size: $fontSize;
  position: absolute;
  color: $textColor;
  z-index: 3;
}

.hexagon {
  position: relative;
  width: $width;
  height: $height;
  z-index: 1;
  background-color: $color;
  margin: $offset 0;
  box-shadow: 3px 1px 12px #0000004a;
  border-left: solid 1px rgb(255, 255, 255);
  border-right: solid 1px rgb(255, 255, 255);
}

.hexagon:before,
.hexagon:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: $triangleSide;
  height: $triangleSide;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 4px;
}

.hexagon:before {
  top: -63%;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.hexagon:after {
  bottom: -69%;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
}
