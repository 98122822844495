@import '../../../dependencies/scss/variables.scss';

$size: calc(var(--size) * 1px);

.wrapper {
  position: relative;
  width: $size;
  height: $size;
  min-width: $size;

  &.outlined {
    padding: 4px;
    border-radius: 50%;
    border: solid 5px $gray-color-3;
  }

  &.green {
    border-color: $green-color-1;
    .path {
      stroke: $green-color;
    }
  }
  &.blue {
    border-color: $blue-color-1;
    .path {
      stroke: $blue-color;
    }
  }
  &.yellow {
    border-color: $yellow-color-2;
    .path {
      stroke: $yellow-color;
    }
  }
  &.red {
    border-color: $red-color-1;
    .path {
      stroke: $red-color;
    }
  }
  &.gray .path {
    stroke: $gray-color-6;
  }
  &.darkGray .path {
    stroke: $gray-color-7;
  }

  &.white .path {
    stroke: white;
  }
  &.white .trail {
    stroke: rgba(255, 255, 255, 0.5);
  }
  &.white .text {
    fill: white;
  }
}

.icon {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(20%, 20%);
}

.container {
  width: 100%;
}

.path {
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.trail {
  stroke: $gray-color-4;
}

.text {
  fill: $gray-color-6;
  font-size: 38px;
  font-family: 'Lato';
  dominant-baseline: middle;
  text-anchor: middle;
}
