@import '../../../../dependencies/scss/variables.scss';

.positionCandidates {
  border: 1px solid $gray-color-4;
  border-bottom: none;
  &_filter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_button {
    margin-left: 8px;
    flex-shrink: 0;
  }
  &_list {
    overflow-x: auto;
    border-bottom: 1px solid $gray-color-4;
    &_header {
      border-bottom: none;
    }
    &_title {
      width: 280px;
      min-width: 280px;
    }
    &_type {
      width: 170px;
      min-width: 170px;
    }
  }
}
