@import '../../../dependencies/scss/variables.scss';

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid $gray-color-4;
  transition: border-color 200ms linear;
  &.hoverable:not(.error):not(.focused):hover {
    border-color: $gray-color-5;
  }
  &.focused {
    border-color: $blue-color-5;
  }
  &.error {
    border-color: $red-color;
  }
  &.error > .input {
    color: $red-color;
    font-family: 'Lato';
  }
  &.error > .currency {
    background-color: $red-color-1;
    color: $red-color-8;
  }
  &.disabled {
    background-color: $gray-color-1;
  }
  &.disabled > .currency {
    background-color: $gray-color-2;
    color: $gray-color-6;
  }
  &.empty > .currency:after {
    content: '-';
    position: absolute;
    left: -10px;
    font-family: 'Lato';
    color: $gray-color-5;
  }
}

.input {
  flex: 1 0 25px;
  width: 100%;
  min-width: 25px;
  background-color: #fff;

  &.highlighted {
    color: $blue-color-6;
    font-family: 'Lato';
  }
}

.control {
  position: relative;
  width: 20px;
  align-items: center;
  justify-content: center;
}

.currency {
  background-color: $gray-color-2;
  color: $gray-color-6;
  font-family: 'Lato';
  font-size: 14px;
  display: flex;
}
.button {
  height: 18px;
  text-align: center;
  background-color: $gray-color-2;
  transition: background-color 200ms linear;
  cursor: pointer;

  &:hover {
    background-color: $gray-color-4;
  }
}

.useForAll {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: 'Lato-Bold';
  font-size: 12px;
  transform: translateY(calc(100% + 1px));
  padding-top: 2px;

  &::selection {
    background-color: transparent;
  }

  &.popover {
    padding: 4px 10px;
    background-color: #fff;
    border: 1px solid $gray-color-4;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 2px 2px -2px $gray-color-6;
    z-index: 10;
  }
}
