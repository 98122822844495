@import '../../../../dependencies/scss/variables.scss';

.barChart {
  height: 200px;
}

.score {
  font-family: 'Lato-Bold';
  font-size: 10px;
}

.label {
  fill: $gray-color-8;
  font-family: 'Lato-Bold';
  font-size: 12px;

  &.labelStart {
    text-anchor: start;
  }
  &.labelCenter {
    text-anchor: middle;
  }
  &.labelEnd {
    text-anchor: end;
  }
}

// axes line
.axisX path {
  stroke: $gray-color-4;
  stroke-width: 1px;
}

// default scores on X axis (0, 20, 40, 60, 80, 100) and candidates count on Y axis
.axisX text:not(.medianScore):not(.candidateScore),
.axisY text {
  @extend .score;
  fill: $gray-color-6;
}

// Y axis line (hide it)
.axisY path {
  stroke-width: 0;
}

// grid for Y axis
.axisY line {
  stroke-width: 0.5px;
  stroke: $gray-color-4;
}

.medianScore {
  @extend .score;
  fill: $gray-color-8;
}

.scoreLine {
  fill: none;
  stroke: $green-color-6;
  stroke-dasharray: 5;
  stroke-width: 1;
}

.medianLine {
  @extend .scoreLine;
  stroke: $gray-color-8;
}

.medianLabel {
  @extend .label;
  fill: $gray-color-8;
}

.tooltip {
  padding: 8px 10px;
  transform: translateY(-12px);
}
