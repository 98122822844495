@import '../../../../dependencies/scss/variables.scss';

.banner {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  padding: 16px 80px;
  color: $blue-color-9;
  background-color: $blue-color-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
}

.text {
  font-size: 14px;
}

.link {
  display: inline-block;
  color: $blue-color-9;
  font-size: 14px;
  font-family: 'Lato-Bold';

  &:hover {
    text-decoration: underline;
  }
}

.buttons {
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkButton {
  flex: 1 0 auto;
  margin-right: 15px;
  font-family: 'Lato';
  font-size: 15px;
  color: $blue-color-9;
}

.primaryButton {
  background: red;
}
