@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.text {
  color: $gray-color-7;
  font-family: Lato-Bold;
  font-size: 12px;
}

.container {
  height: 100%;
}

.card {
  display: flex;
  height: 100%;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover {
    transform: scale(1.01);
    @include hoverStyle();
  }
}

.content {
  padding: 24px;
  background-color: white;
  border: 1px solid $gray-color-4;
  border-top: 4px solid $gray-color-4;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.red {
    border-top-color: $red-color-5;
  }
  &.green {
    border-top-color: $green-color-4;
  }
  &.yellow {
    border-top-color: $yellow-color-4;
  }
  &.gray {
    border-top-color: $gray-color-6;
  }
  &.blue {
    border-top-color: $blue-color-5;
  }
}

.controls {
  display: flex;
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: transparent;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s linear;
  &.active {
    opacity: 1;
  }
}

.control {
  padding: 1px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 2px 1px 12px #00000017;
}

.statusFlow {
  margin-bottom: 32px;
}

.main {
  margin-top: 24px;
  flex: 1;

  .info {
    display: flex;
    align-items: center;
    font-size: 14px;
    & > div {
      padding-left: 8px;
    }
  }
}

.header {
  display: flex;

  .positionInfo {
    flex: 1;
    margin-right: 15px;
  }
  .title {
    font-size: 16px;
    font-family: Lato;
    padding: 8px 0 12px 0;
    word-break: break-word;
  }
  .project {
    background-color: $gray-color-3;
    color: $gray-color-8;
    font-size: 10px;
    font-family: Lato-Bold;
    padding: 4px 12px;
    border-radius: 20px;
  }
  .rfq {
    @extend .text;
    padding-right: 12px;
    align-items: center;
    display: flex;
    color: inherit;
  }
}

.rates {
  text-align: right;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .rate {
    font-size: 24px;
    font-family: Lato-Bold;
    &.smallerRate {
      font-size: 22px;
    }
    .placeholder {
      color: $gray-color-4;
      display: flex;
    }
  }
  .nte {
    @extend .text;
    padding-top: 8px;
  }
  .emptyRate {
    color: $gray-color-5;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audience {
  flex: 1 0 auto;
  margin-right: 12px;
}

.positionStatus {
  @extend .text;
  position: absolute;
  top: 4px;
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 0 0 5px 5px;
  color: $gray-color-8;
  background-color: $gray-color-3;

  &.published {
    color: $red-color-7;
    background-color: $red-color-1;
  }
}
