@import '../../../dependencies/scss/variables.scss';

.banner {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid $gray-color-4;
  border-left: 3px solid;

  &.blue {
    border-left-color: $blue-color;
    & > .icon {
      background-color: $blue-color-1;
    }
  }
  &.yellow {
    border-left-color: $yellow-color;
    & > .icon {
      background-color: $yellow-color-2;
    }
  }
  &.red {
    border-left-color: $red-color;
    & > .icon {
      background-color: $red-color-1;
    }
  }
  &.green {
    border-left-color: $green-color;
    & > .icon {
      background-color: $green-color-1;
    }
  }
  &.gray {
    border-left-color: $gray-color-6;
    & > .icon {
      background-color: $gray-color-3;
    }
  }
}

.icon {
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  padding-left: 15px;
}
