@import '../../../dependencies/scss/variables.scss';

.positionDetails {
  &_section {
    height: 100%;
    & > div {
      min-height: 100%;
    }
  }
  .textSection_title {
    color: $text-color;
  }
  &_requirements {
    font-size: 15px;
    &_levels {
      display: flex;
      flex-wrap: wrap;
      margin-right: -3px;
    }
    &_level {
      margin-right: 3px;
      margin-top: 3px;
      width: 75px;
    }
    &_activity {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 10px;
    }
    & .donutChart :hover {
      cursor: initial;
    }
  }
}
