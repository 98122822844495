@import '../../../../dependencies/scss/mixins.scss';

.attachments {
  margin-bottom: -15px;
  @include grid(200px);
}

.attachment {
  margin-bottom: 15px;
}

.link {
  word-break: break-all;
}
