@import '../../../dependencies/scss/variables.scss';

.section {
  padding-bottom: 24px;
  &_title {
    font-family: Lato-Bold;
    font-size: 21px;
    padding-bottom: 8px;
  }
  &_content {
    line-height: 1.3;
    & a {
      text-decoration: underline;
      font-family: Lato;
      color: $blue-color;
      &:hover,
      &:focus {
        text-decoration: underline;
        color: $blue-color;
      }
    }
  }
}
