@import '../../../dependencies/scss/variables.scss';

@mixin colors($backgroundColor, $fontColor) {
  background-color: $backgroundColor;
  color: $fontColor;
}

.container {
  display: inline-block;
  font-family: Lato-Bold;
  letter-spacing: 0.5px;
  border-radius: 15px;

  &.sm {
    font-size: 10px;
    padding: 2px 6px;
  }
  &.md {
    font-size: 12px;
    padding: 3px 8px;
  }
  &.lg {
    font-size: 14px;
    padding: 5px 15px;
  }

  &.red {
    @include colors($red-color-1, $red-color-7);
  }
  &.green {
    @include colors($green-color-1, $green-color-7);
  }
  &.blue {
    @include colors($blue-color-1, $blue-color-8);
  }
  &.yellow {
    @include colors($yellow-color-2, $yellow-color-9);
  }
  &.gray {
    @include colors($gray-color-3, $gray-color-8);
  }
}
