@import '../../../dependencies/scss/variables.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding-left: 10px;
  padding-right: 4px;
  border: solid thin $gray-color-4;
  background-color: white;

  & > * {
    box-sizing: border-box;
  }
}

.input {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 5px;
  overflow: hidden;
}

.chip {
  display: flex;
  align-items: center;
  min-width: 45px;
  overflow-x: hidden;
}

.deleteIcon {
  display: inline-flex;
  margin-left: 2px;
}

.placeholder {
  color: $gray-color-6;
  font-size: 13px;
  font-family: 'Lato-Italic';
}

.icon {
  display: inline-flex;
  color: $gray-color-6;
}

.moreItems {
  margin-left: 5px;
  flex: 1 0 auto;
}

.checkboxList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px;
  min-height: 50px;
  max-height: 400px;
  overflow-y: auto;
  outline: solid thin $gray-color-4;
  background-color: white;
  z-index: 110;
}
