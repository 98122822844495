@import '../../../dependencies/scss/variables.scss';

.notificationSettings {
  display: flex;
  &_menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    & .menuOption-item {
      border-left: 2px solid transparent;
      border-bottom: none;
      background-color: transparent;
      text-transform: uppercase;
      font-size: 13px;
      font-family: Lato-Bold;
      padding: 4px 12px;
      margin: 8px 32px 8px 0;
      display: flex;
      align-items: center;
      & > svg {
        margin-right: 12px;
      }
      &:hover {
        border-left: 2px solid #a0a0a0;
        border-bottom: none;
      }
      &.active {
        border-bottom: none;
        border-left: 2px solid $atrament-color;
      }
    }
  }
  &_content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    border: 1px solid $gray-color-4;
    &-header {
      border-bottom: 1px solid $gray-color-4;
    }
  }
  &_event {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    &Info {
      &-title {
        font-family: Lato-Bold;
        font-size: 16px;
      }
      &-description {
        padding-top: 8px;
      }
      &-header {
        font-family: Lato-Bold;
        font-size: 14px;
        color: $gray-color-7;
      }
      flex: 3;
    }
    &-switch {
      flex: 1;
      display: flex;
      & > div {
        margin-right: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    &-switchElement {
      padding: 15px 0;
    }
  }
}
