@import '../../../dependencies/scss/variables.scss';

.container {
  padding: 48px;
  background: $gray-color-1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  font-family: 'Lato';
  color: $gray-color-8;
}

.description {
  line-height: 1.3;
  font-size: 16px;
  margin-top: 16px;
  text-align: justify;
}

.comparableDescription {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  margin-top: 25px;
}

.comparableText {
  font-size: 12px;
  font-family: 'Lato';
  color: $gray-color-8;
  letter-spacing: 0;
}

.comparableCount {
  font-size: 20px;
  margin-right: 8px;
  font-family: Lato;
}

.comparableCriteria {
  display: flex;
  margin-top: 16px;
}

.comparableCriteriaText {
  flex-basis: 170px;
  flex-shrink: 0;
  font-size: 12px;
  font-family: 'Lato';
  color: $gray-color-8;
  margin-right: 5px;
  margin-top: 4px;
}

.criteriaChips {
  margin-bottom: -4px;
}

.criteriaChip {
  margin-bottom: 4px;
  letter-spacing: inherit;
}
