@import '../../../../dependencies/scss/variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rfq {
  font-family: 'Lato';
  font-size: 12px;
  margin-bottom: 2px;
}

.title {
  font-size: 14px;
}

.evaluationMethod {
  flex-shrink: 0;
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $gray-color-7;
}

.submittedCandidatesCount {
  margin-top: 2px;
  font-size: 12px;
}

.headerYears {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(55px, 1fr));
  justify-items: end;
  align-items: center;
  column-gap: 12px;
  margin-top: 4px;
  margin-bottom: 1px;
  font-family: 'Lato';
}

.nameLabel {
  overflow: hidden;
  font-family: 'Lato';
}

.tooltip {
  font-family: 'Lato';
  font-size: 12px;
}

.error {
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $red-color;
}

.disabledText {
  color: $gray-color-6;
}

.disabledElement {
  opacity: 0.5;
}

.previouslySaved {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  font-family: 'Lato';
  color: $gray-color-6;
}
