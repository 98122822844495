@import '../../../dependencies/scss/variables.scss';
.levelComponent {
  text-align: center;
  font-size: 11px;
  font-family: 'Lato';
  padding: 3px;
  border-radius: 3px;
}
.levels {
  display: flex;
  // cursor: pointer;
  flex-wrap: wrap;
  &_levelsItem {
    background-color: $gray-color-4;
    color: $gray-color-8;
    @extend .levelComponent;
    width: 75px;
    // flex-grow: 1;
  }
  &_levelsOnly {
    @extend .levelComponent;
    margin-bottom: 8px;
    padding: 3px 6px;
    min-width: 55px;
  }
  &_empty {
    padding: 0 1px;
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
  &_description {
    font-size: 11px;
    color: $gray-color-6;
    font-family: 'Lato';
    text-align: center;
  }
}
.highlighted {
  background-color: $blue-color;
  color: white;
}
.green .highlighted {
  background-color: $green-color;
}
.red .highlighted {
  background-color: $red-color;
}
.yellow .highlighted {
  // background-color: $yellow-color;
  background-color: $gray-color-6;
}
.gray .highlighted {
  background-color: $gray-color-6;
}
