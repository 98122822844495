@import '../../../dependencies/scss/variables.scss';
.link {
  margin-bottom: -25px;
}
.form_htmlElement {
  &.htmlElement-link {
    margin-bottom: 0;
    margin-top: -17px;
    padding: 5px 0;
    background-color: white;
    color: inherit;
    font-size: 15px;
    & a {
      color: $blue-color;
      text-decoration: underline;
      font-family: Lato;
    }
  }
}
