.statusFlowTitle {
  font-size: 15px;
  margin-bottom: 20px;
}

.statusFlowContent {
  min-width: 450px;
  padding: 20px;
}

.chipContainer {
  display: block;
  overflow: hidden;
}

.chipContent {
  display: flex;
  align-items: center;

  &.md {
    font-size: 11px;
  }
}

.chipLabel {
  margin-right: 2px;
}

.icon {
  display: inline-flex;
}
