@import '../../../../dependencies/scss/variables.scss';

.revisionsList {
  margin-top: -15px; // reset 1. element top padding
}
.revision {
  &_header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  &_title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &_text {
      margin-right: 5px;
      font-size: 15px;
      font-family: Lato-Bold;
    }
  }
  &_author {
    font-size: 11px;
    font-family: Lato;
    color: $gray-color-6;
  }
  &_type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-color-2;
    padding: 15px;
    &_index {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      text-align: center;
      width: 30px;
      height: 30px;
      min-width: 30px;
      border-radius: 50%;
      background-color: $gray-color-6;
      color: white;
      font-size: 13px;
      font-family: Lato;
    }
    &_name {
      font-size: 13px;
      font-family: Lato-Bold;
      margin-bottom: 2px;
    }
    &_status {
      margin-bottom: 5px;
    }
    &_button {
      min-width: 90px;
    }
  }
}
