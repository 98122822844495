@import '../../../dependencies/scss/variables.scss';

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-color-6;
  border: 1px solid $gray-color-4;
  padding: 5px 10px;
  padding-left: 20px;
  transition: background-color 200ms ease-out;

  &:hover {
    background-color: $gray-color-3;
  }
}

.actionButtonLabel {
  font-family: 'Lato-Bold';
  font-size: 11px;
  text-transform: uppercase;
  color: $text-color;
  margin-right: 5px;
}
