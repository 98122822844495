$blue: #109fc6;
$blue-light: #29b2d8;
$blue-lightest: #d3ecf3;
$blue-dark: #0e8fb4;
$blue-darkest: #0e5574;
$gray: #e6e6e6;
$gray-light: #f6f6f6;
$gray-lightest: #fbfbfb;
$gray-dark: #a0a0a0;
$gray-darkest: #676767;
$red: #d0314b;
$red-lightest: #f5cfd5;
$red-dark: #a51b32;
$red-darkest: #8a1629;
$green: #00bea1;
$green-lightest: #cbf0e7;
$green-dark: #04a78e;
$green-darkest: #025f50;
$yellow: #efcb4f;
$yellow-lightest: #f9e6a6;
$yellow-dark: #e0bc3f;
$yellow-darkest: #7b5d10;