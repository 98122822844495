@import '../../../dependencies/scss/variables.scss';

.level {
  text-align: center;
  font-size: 11px;
  font-family: 'Lato';
  padding: 3px 10px;
  border-radius: 3px;
  color: $gray-color-8;
  background-color: $gray-color-4;
  &_description {
    color: $gray-color-6;
  }
  &--highlighted {
    color: #fff;
  }
}
