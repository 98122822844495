@import '../../../../dependencies/scss/variables.scss';
$highlight-color: $atrament-color;

.navigation {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background-color: $gray-color-1;
  padding-top: 30px;
  z-index: 2;
}

.otherOptions {
  & > div {
    &.highlight {
      border-top-color: $highlight-color;
    }
  }
}

@mixin highlight($color) {
  &Border {
    & .circleTab {
      &_icon {
        border-color: $color;
      }
    }
  }
  & .circleTab {
    &_icon {
      background-color: $color;
      border-color: $color;
    }
    &_label {
      color: $color;
    }
  }
}

.highlight {
  @include highlight($highlight-color);
}

progress {
  border: 0;
  // width: 100%;
  background: $gray-color-4;
  height: 2px;
  width: 100%;
  transition: all 200ms ease-out;
  &[value] {
    transition: all 200ms ease-out;
  }
  &::-moz-progress-bar {
    background: $highlight-color;
    transition: all 200ms ease-out;
  }
  &::-webkit-progress-bar {
    background: $gray-color-4;
    transition: all 200ms ease-out;
  }
  &::-webkit-progress-value {
    transition: all 200ms ease-out;
    background: $highlight-color;
  }
}

@mixin onboardingColorSetup($color) {
  & progress {
    &::-moz-progress-bar {
      background: $color;
    }
    &::-webkit-progress-value {
      background: $color;
    }
  }
  & .menuOption {
    &-item {
      color: $color;
      &.active {
        border-bottom-color: $color;
      }
    }
  }
  & .circleTabs {
    &_progress {
      &.completed {
        color: $color;
        border-color: $color;
      }
    }
  }
  & .highlight {
    @include highlight($color);
  }
  .circleTabsContent {
    & .card_header {
      background-color: $color;
    }
  }
  & .circleTab {
    &:hover {
      &Border {
        & .circleTab {
          &_icon {
            border-color: $color;
          }
        }
      }
      & .circleTab {
        &_icon {
          border-color: $color;
        }
        &_label {
          color: $color;
        }
      }
      &.notHighlight {
        & .circleTab_icon {
          & > svg {
            color: $color !important;
          }
        }
      }
    }
  }
}

.SUPPLIER {
  @include onboardingColorSetup($brownish-color);
}
.MANAGER {
  @include onboardingColorSetup($manager-color);
}
