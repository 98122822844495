@import '~@styles/colors';

.container {
  border-radius: 0;
}

.header {
  padding: 45px 45px 25px;
}

.titleWrapper {
  display: flex;

  & > h2 {
    flex: 1 0 auto;
  }
}

.closeIcon {
  margin-left: 8px;
}

.body {
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 25px 45px;
  padding-top: 0;
}

.hiddenContent {
  visibility: hidden;
  max-height: 300px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray-dark;
}

.loadingText {
  margin-top: 12px;
  font-size: 16px;
  font-family: 'Lato-Light';
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  padding: 0 45px 45px;
}