@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

$size-padding: 30px;

.page {
  &_content {
    padding: 20px $size-padding;
    background-color: $gray-color-1;
    border-top: 1px solid $gray-color-4;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &_header {
    padding: 20px $size-padding 0 $size-padding;
  }
  &_filter {
    padding: 10px $size-padding 20px $size-padding;
  }
}
