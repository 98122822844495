.question {
  font-family: 'Lato';
}

.body {
  line-height: 1.3;
}

.footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
