@import '../../../dependencies/scss/variables.scss';

$loaderColor: black;
.loader {
  position: relative;
  padding-top: 5px;
  opacity: 0.3;
  &-loader {
    @extend .gooey;
    right: -52%;
  }
  &-fixedWidth {
    width: 110px;
  }
  &-fixedSize {
    width: 110px;
    height: 16px;
    padding: 0;
  }
  &-small {
    transform: scale(0.8);
  }
}
.gooey {
  position: absolute;
  top: 50%;
  // left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background: #fff;
  filter: contrast(20);
  &-moved {
    @extend .gooey;
    left: 50%;
  }
}
.gooey .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  filter: blur(4px);
  background: $loaderColor;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.gooey .dots {
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}
.gooey .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  filter: blur(4px);
  background: $loaderColor;
  border-radius: 50%;
}
.full-screen {
  width: 100%;
  height: 100vh;
}
@-moz-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-webkit-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-o-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@-moz-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@-webkit-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@-o-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
