@import '../../../../../dependencies/scss/variables.scss';

.container {
  border: 1px solid $gray-color-4;
  font-size: 14px;
  padding: 16px;
  cursor: pointer;

  &.error {
    border-color: $red-color;
  }
  &.error .label {
    color: $red-color;
  }
}

.header {
  display: flex;
  align-items: center;
}

.label {
  font-family: 'Lato';
  margin-left: 4px;
}

.emptyLabel {
  font-family: 'Lato-Italic';
  color: $gray-color-6;
}

.content {
  margin-top: 10px;
}

.errorMessage {
  margin-top: 4px;
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $red-color;
}

.rateSetting {
  position: relative;
  padding-left: 10px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $text-color;
  }
}

.rates {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.rate {
  min-width: 100px;

  &.error > .rateYear {
    color: $red-color;
  }
  &.error > .rateValue {
    font-family: 'Lato';
    color: $red-color;
  }
}

.rateYear {
  font-family: Lato-Bold;
  color: $gray-color-7;
}

.rateValue {
  margin-top: 4px;
}

.relativeDueDate {
  margin-top: 5px;
  color: $gray-color-7;
  font-family: 'Lato-Bold';
  font-size: 12px;
}

.audience {
  font-family: 'Lato';
}

.note {
  line-height: 1.2;
}
