@import '../../../dependencies/scss/variables.scss';

.container {
  font-family: 'Lato-Light';
  padding: 30px 50px;
  text-align: center;
}

.content {
  max-width: 700px;
  margin: 0 auto;
}

.code {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato-Bold';
  color: $gray-color-4;
  font-size: 128px;
  padding-top: 12px;
}

.line {
  width: 50px;
  height: 4px;
  background-color: $blue-color;
  margin: 8px auto 0;
}

.title {
  font-size: 32px;
  margin-top: 24px;
  color: $gray-color-7;
  font-family: 'Lato';
}

.description {
  margin-top: 12px;
  font-size: 16px;
  line-height: 1.3;
  color: $gray-color-8;
  > * {
    text-decoration: none;
  }
}
