.container {
  display: inline-block;
  width: 100%;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // overflow: hidden crops some fonts. In out case the bottom parts of letters "g", "j" etc.
  // line-height: normal fix this problem
  line-height: normal;
}