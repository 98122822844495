@import '../../../../../dependencies/scss/variables.scss';
@import '../navigation.scss';

$highlight-color: $atrament-color;
$iconCirle: 55px;

.circleTabs {
  padding: 0 100px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: -50px;
  z-index: 1;
  transition: all 200ms ease-out;
  &_progressBar {
    position: absolute;
    width: 100%;
    top: 32px;
  }
  &_progress {
    position: absolute;
    background-color: white;
    border: 1px solid $gray-color-4;
    color: $gray-color-6;
    padding: 0 6px 0 16px;
    border-radius: 50px;
    font-size: 11px;
    font-family: Lato-Bold;
    right: 0;
    top: 32px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    line-height: 1.1;
    &.completed {
      color: $highlight-color;
      border-color: $highlight-color;
    }
  }
}

.circleTab {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  transition: all 200ms ease-out;
  min-width: 120px;
  &.highlight {
    @include highlight($highlight-color);
  }
  &_icon {
    border-radius: 50%;
    font-family: Lato;
    border: 2px solid $gray-color-4;
    background-color: white;
    width: $iconCirle;
    height: $iconCirle;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-out;
    & > svg {
      transition: all 200ms ease-out;
    }
  }
  &_priority {
    position: absolute;
    top: 6px;
    right: -17px;
    margin-left: 2px;
  }
  &_label {
    transition: all 200ms ease-out;
    font-size: 11px;
    text-transform: uppercase;
    font-family: Lato-Bold;
    color: $gray-color-6;
    padding-top: 8px;
    position: relative;
  }
  &_notification {
    background-color: $blue-color;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin-left: 2px;
    position: absolute;
    top: 6px;
    right: -8px;
  }
  &_notificationIcon {
    background-color: $blue-color;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

$circleTabsBackground: white;

.circleTabsContent {
  padding: 50px;
  border: 1px solid $gray-color-4;
  border-top: none;
  background-color: $circleTabsBackground;
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-out;
  & .card_header {
    padding: 12px 15px;
    background-color: $highlight-color;
    font-size: 17px;
  }
}
