@import './variables.scss';
@import './flexMixins.scss';

.list {
  display: grid;
  grid-gap: $gridGap;
  grid-template-columns: repeat(auto-fill, minmax($cardSize, 1fr));
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
  box-sizing: border-box;
}
.pageHeading {
  font-size: 25px;
  font-family: 'Lato-Light';
}
.center {
  @extend .display-flex;
  justify-content: center;
  align-items: center;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.display-flex {
  display: flex;
  flex-direction: column;
}
.display-flex-row {
  display: flex;
}
.flex1 {
  @include flex(1);
}
.flexC1 {
  @include flexIECombination();
}
.flex2 {
  @include flex(2);
}
.flex_space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.oneRow {
  display: flex;
  align-items: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.smallText {
  font-size: 11px;
  color: $gray-color-6;
}
.flexStart {
  justify-content: flex-start;
}
.flexEnd {
  justify-content: flex-end;
}
.flex1Basic {
  /* autoprefixer: off */
  flex: 1;
}
.p1 {
  padding: 10px 15px 5px 15px;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.wrap {
  flex-wrap: wrap;
}
.wrapReverse {
  flex-wrap: wrap-reverse;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
}
.stretch {
  align-items: stretch;
}
.border_bottom {
  border: none;
  border-bottom: 1px solid $gray-color-4;
}
.noBorder {
  &_bottom {
    border-bottom: none;
  }
}
.verticalCenter {
  display: flex;
  align-items: center;
}
.horizontalCenter {
  display: flex;
  justify-content: center;
}
.alignSelfCenter {
  display: flex;
  align-self: center;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 1100;
  min-width: 520px;
}
.menu-width {
  width: $menu-big-width;
}
.defaultText {
  font-family: 'Lato-Light';
  font-size: 13px;
  color: $text-color;
  text-transform: initial;
}
.textInitial {
  text-transform: initial;
}
.pageHeader-default {
  padding: 50px 50px 0 50px;
}

.scrollableContent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow-y: auto;
  &.panelOpen {
    left: $side-panel-width + 41px;
  }
}

.relativePosition {
  position: relative;
}

.mainHeader {
  text-align: center;
  font-size: 25px;
  padding-bottom: 20px;
  padding-top: 20px;
  &.p-b-0 {
    padding-bottom: 0;
  }
}

.linkDisabled {
  cursor: default;
}

.scrollingPageContent {
  height: 100%;
  overflow-y: auto;
}

.disabledPointer {
  cursor: not-allowed;
  & > * {
    pointer-events: none;
  }
}
