@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/flexMixins.scss';

.upload {
  cursor: pointer;
  border: 2px dashed $gray-color-4;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;

  &.active {
    border-color: $blue-color;
  }
  &.error {
    border-color: $red-color;
  }
  &.disabled {
    background-color: $gray-color-2;
    cursor: not-allowed;
  }
}

.browse {
  text-decoration: underline;
}

.files {
  display: flex;
  flex-direction: column;
}

.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid $gray-color-4;

  &.inactive {
    background-color: $gray-color-2;
  }
}

.preview {
  display: flex;
  align-items: center;
}

.fileInfo {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.filename {
  font-family: 'Lato';
}

.link {
  display: flex;
  align-items: center;

  &.clickable {
    cursor: pointer;
    transition: all 200ms ease-out;
    &:hover {
      color: darken($blue-color, 20%);
    }
  }
  &.notClickable {
    cursor: not-allowed;
  }
}

.size {
  margin-top: 2px;
  font-family: 'Lato-Italic';
  font-size: 13px;
}

.progressBarColor {
  background-color: $blue-color-4 !important;
}

.progressBarSecondaryColor {
  background-color: $blue-color-1 !important;
}
