@import '../../../../dependencies/scss/variables.scss';

.container {
  display: flex;
  justify-content: space-between;
}

.leftSection {
  display: flex;
  flex: 1;
  margin-right: 30px;
}

.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.title {
  font-family: 'Lato';
  font-size: 16px;
}

.subTitle {
  color: $gray-color-7;
  font-family: 'Lato';
  font-size: 12px;
  margin-left: 10px;
}

.positionName {
  font-family: 'Lato';
  font-size: 18px;
}

.status {
  margin-left: 10px;
  margin-right: 10px;
}

.icon {
  margin-right: -5px;
}

.banner {
  background-color: white;
}
