.container {
  position: relative;
  width: 310px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outerCircle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
