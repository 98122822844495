@import '~@styles/colors';

.body {
  display: grid;
  row-gap: 4px;
}

.baseRow {
  display: grid;
  column-gap: 16px;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  padding: 0 10px;
}

.row {
  @extend .baseRow;
  align-items: center;
  background-color: $gray-lightest;
  box-shadow: 0 0 1px transparent;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.cell {
  border-bottom: none;
  padding: 12px 0;
  overflow: hidden;
}

.header {
  text-transform: uppercase;
  font-family: "Lato-Bold";
  font-size: 11px;
  color: $gray-dark;
  line-height: 1.2;
}