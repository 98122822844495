.blue {
  color: #4183f0;
}

.green {
  color: #0f9d58;
}

.red {
  color: #db4437;
}

.gray {
  color: #a0a0a0;
}
