.container {
  width: 380px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.text {
  line-height: 1.3;
  margin-left: 20px;
}

.title {
  margin-bottom: 10px;
  font-family: 'Lato-Bold';
  font-size: 15px;
}

.popper {
  z-index: 1400;
}
