@import '../../../../dependencies/scss/variables.scss';

.wrapper {
  display: flex;
  align-items: flex-start;
}

.icon {
  display: flex;
  padding: 3px;
  border-radius: 50%;
  color: $red-color-8;
  background-color: $red-color-1;

  &.tracked {
    color: $green-color-8;
    background-color: $green-color-1;
  }
}

.label {
  font-size: 14px;
  line-height: 1.3;
}

.type {
  margin-top: 3px;
  color: $gray-color-7;
  font-family: 'Lato-Bold';
  font-size: 12px;
}
