@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/flexMixins.scss';
@import '../../../dependencies/scss/mixins.scss';

$height: 36px;
$width: 150px;
@mixin container() {
  border: 1px solid $gray-color-4;
  // width: 150px;
  font-size: 13px;
  // padding: 0 12px;
}
.checkboxContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  &_header {
    height: $height;
    // color: $gray-color-6;
    @include container();
    padding: 0 4px 0 10px;
    &-italic {
      font-family: 'Lato-Italic';
      color: $gray-color-6;
    }
    &-short {
      @include truncatedText();
      // width: $width - 26px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    // cursor: pointer;
    @include flex(1, 0, 0);
    &_icons {
      display: flex;
      align-items: center;
      align-self: stretch;
      flex-shrink: 0;
      box-sizing: border-box;
      &-right {
        & > svg {
          padding-left: 4px;
        }
      }
    }
    &-separator {
      align-self: stretch;
      background-color: hsl(0, 0%, 80%);
      margin-bottom: 8px;
      margin-top: 8px;
      width: 1px;
      box-sizing: border-box;
    }
    &.disabled {
      background-color: $gray-color-2;
    }
  }
  &_content {
    position: absolute;
    z-index: 31;
    background-color: white;
    @include container();
    // padding-top: 12px;
    border-top: none;
    max-height: 400px;
    overflow-y: auto;
    width: calc(100% - 2px);
  }
  &_space {
    padding: 12px;
  }
}
.checkboxLine {
  transition: 0.3s;
  &:hover {
    background-color: $gray-color-3;
  }
}
