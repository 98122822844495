@import '../../../dependencies/scss/variables.scss';

.section {
  height: 100%;

  & > div {
    min-height: 100%;
  }
}

.revisions {
  overflow-y: auto;
  max-height: 400px;
}

.documentsContainer {
  display: flex;
}

.documents {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 15px;
  row-gap: 15px;
}
