@import '../../../dependencies/scss/variables.scss';

.container {
  display: flex;
  align-items: center;

  &.disabled > .label {
    color: $gray-color-7;
  }
  &.disabled > .icon {
    color: $gray-color-5;
  }
}

.icon {
  display: inline-flex;
  color: $gray-color-6;

  &.checked {
    color: $blue-color;
  }
}

.label {
  margin-left: 8px;
  font-size: 14px;
  font-family: 'Lato-Light';

  &.thicker {
    font-family: 'Lato';
  }
}

.description {
  margin-left: 5px;
  font-size: 13px;
  font-family: 'Lato-Light';
}

.labelWrapper {
  display: inline-flex;
}
