@import '../../../dependencies/scss/variables.scss';

.container {
  font-size: 14px;
  line-height: 1.3;

  & strong {
    font-family: 'Lato';
  }

  & h3 {
    font-size: 20px;
    font-family: 'Lato';
    margin-bottom: 10px;
  }

  & h4 {
    font-size: 17px;
    font-family: 'Lato';
    margin-bottom: 8px;
  }

  & p {
    margin-bottom: 8px;
  }

  & a {
    text-decoration: none;
    color: $blue-color-4;
    font-family: "Lato";

    &:visited,
    &:focus {
      color: $blue-color;
      text-decoration: none;
    }
    &:hover {
      color: $blue-color;
      text-decoration: underline;
    }
  }

  & ol, & ul {
    margin-top: 8px;
  }

  & ul > li::before {
    content: "- ";
  }
  
  & ol > li {
    counter-increment: inst;

    &::before {
      content: counter(inst)  ". ";
    }
  }
}