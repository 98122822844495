@import '../../../dependencies/scss/variables.scss';

.container {
  padding: 0;
}

.header {
  padding: 45px;
  padding-bottom: 0;
}

.title {
  font-size: 24px;
  font-family: 'Lato';
}

.subtitle {
  font-family: 'Lato';
  margin-top: 8px;
  font-size: 12px;
  color: $gray-color-7;
}

.cvLink {
  position: relative;
  margin: 0 5px;
  font-size: inherit;
  color: inherit;
  text-decoration: underline;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: inherit;
  }
}

.chart {
  display: flex;
  justify-content: center;
}

.description {
  font-size: 16px;
  margin-top: 24px;
  text-align: center;
}

.levels {
  width: 200px;
  margin: 0 auto;
  margin-top: 10px;
}

.levelChip {
  flex: 1;
}

.body {
  min-height: 300px;
}

.section {
  padding: 32px 45px;
}

.topSection {
  @extend .section;
  padding-top: 0;
}

.bottomSection {
  @extend .section;
  background-color: $gray-color-2;
}

.factorsTitle {
  font-family: 'Lato';
  font-size: 18px;
  padding-bottom: 24px;
}
