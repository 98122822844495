@import '../../../dependencies/scss/variables.scss';

.label {
  font-family: 'Lato-Bold';
  font-size: 11px;
  color: $text-color;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.text {
  font-size: 15px;
  font-family: Lato-Light;
}

.detail {
  margin-top: 4px;
  font-size: 11px;
  color: $gray-color-6;
}

.date {
  font-family: Lato;
}
