@import '../../../dependencies/scss/variables.scss';

$badgeSize: 17px;
$borderColor: white;

.notifications {
  font-family: 'Lato';
  max-width: 400px;
  &.infinite-scroll-component {
    display: flex;
    flex-direction: column;
  }
}

.scrollableNotifications {
  max-height: 500px;
}

.notification {
  display: flex;
  align-items: flex-end;
  &_placeholder {
    padding: 15px;
    min-width: 370px;
  }
  &_loader {
    min-width: 400px;
    max-width: 400px;
  }
  &_group {
    padding: 8px 15px;
    text-transform: uppercase;
    font-size: 11px;
    font-family: Lato-Bold;
    color: $gray-color-6;
    border-bottom: 1px solid $gray-color-4;
  }
  &_icon {
    position: relative;
    cursor: pointer;
  }
  &_icon_number {
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: $blue-color;
    font-family: Lato-Bold;
    font-size: 11px;
    border-radius: 50%;
    width: $badgeSize;
    height: $badgeSize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &Item {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-right: 35px;
    font-family: Lato-Light;
    font-size: 15px;
    min-height: 40px;
    &_wrapper {
      position: relative;
      border-bottom: 2px solid $borderColor;
      cursor: pointer;
      transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      &:hover {
        background-color: darken(white, 5%);
      }
      &.unread {
        background-color: $secondary-hover-color;
        &:hover {
          background-color: $secondary-focus-color;
        }
      }
    }
    &_loader {
      padding: 16px 10px 0 0;
    }
    &_controllers {
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      max-width: 45px;
      position: absolute;
      top: 5px;
      right: 5px;
      & > svg {
        color: $gray-color-6;
      }
    }
    &_content {
      flex: 1;
    }
    &-message {
      & a {
        color: $blue-color;
        &:hover {
          color: $blue-color;
          text-decoration: underline;
        }
      }
      & b {
        font-family: 'Lato';
      }
    }
    &-date {
      font-size: 11px;
      font-family: Lato;
      color: $gray-color-6;
      padding-top: 5px;
    }
  }
  &_header {
    font-size: 15px;
    font-family: Lato;
  }
  &_headerContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-color-4;
    padding: 15px;
    background-color: white;
    z-index: 1;
    position: sticky;
    top: 0;
  }
  &_helpers {
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 13px;
    font-family: Lato;
    color: $blue-color;
    &:hover {
      color: $blue-color-6;
    }
  }
}
