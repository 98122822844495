$spacing-1: 2px;  
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 12px;
$spacing-5: 16px;
$spacing-6: 24px;
$spacing-7: 32px;
$spacing-8: 48px;

$space-list: (
  '0': 0,
  '1': $spacing-1,
  '2': $spacing-2,
  '3': $spacing-3,
  '4': $spacing-4,
  '5': $spacing-5,
  '6': $spacing-6,
  '7': $spacing-7,
  '8': $spacing-8
);

$direction-list: (
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

// generate margins and paddings
@each $index, $space in $space-list {
  // for all sides
  .m#{$index} {
    margin: #{$space};
  }
  .p#{$index} {
    padding: #{$space};
  }

  // for horizontal
  .mx#{$index} {
    margin: 0 #{$space};
  }
  // for vertical
  .my#{$index} {
    margin: #{$space} 0;
  }

  // for horizontal
  .px#{$index} {
    padding: 0 #{$space};
  }
  // for vertical
  .py#{$index} {
    padding: #{$space} 0;
  }

  // for each directions
  @each $abbr, $direction in $direction-list {
    .m#{$abbr}#{$index} {
      margin-#{$direction}: #{$space};
    }
    .p#{$abbr}#{$index} {
      padding-#{$direction}: #{$space};
    }
  }
}
