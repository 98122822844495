@import '~@styles/colors';

.container {
  height: 36px;
  border: 1px solid $gray;
  font-size: 13px;
  font-family: "Lato-Light";

  &.clearButtonVisible {
    padding-right: 2px;
  }

  &.focused {
    border-color: $gray-dark;
  }

  &.error {
    border-color: $red;
  }

  &.disabled {
    background-color: $gray-lightest;
  }

  &.disabled > .input {
    -webkit-text-fill-color: $gray-darkest;
  }

  &:not(:global(.Mui-disabled)):hover {
    border-color: $gray-dark;
  }

  .input {
    padding: 0 8px;

    &.hasIcon {
      padding-left: 4px;
    }

    &::placeholder {
      color: $gray-dark;
      font-family: 'Lato-Italic';
      opacity: 1;
    }
  }

  .icon {
    display: inline-flex;
    padding: 0 5px;
    padding-left: 8px;
    color: $gray-dark;
  }
}