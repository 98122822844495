@import '../../../dependencies/scss/variables.scss';

.revision-header {
  display: flex;
  flex-direction: column;

  &_header {
    font-family: 'Lato-Bold';
    color: $atrament-color;
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
  }
  &_content {
    width: 100%;
    padding-bottom: 5px;
  }
  &_container {
    display: flex;
    padding: 40px 40px 15px 30px;
  }
  &_quote {
    font-size: 200px;
    max-height: 60px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    margin-right: -15px;
    margin-top: -2px;
    line-height: 100px;
    color: $gray-color-4;
    transform: scale(-1, 1);
  }
  &_note-container {
    width: 100%;
    z-index: 10;
  }
  &_note {
    line-height: 20px;
  }
  &_author {
    padding-top: 5px;
    max-width: 50%;
    color: $gray-color-6;
    font-family: Lato;
    font-size: 12px;
    border-top: 1px solid $gray-color-4;
  }
}
.link-container {
  margin-right: 0;
  margin-left: auto;
  font-size: 14px;
}

.revision-form {
  display: flex;
  flex-direction: column;
  &_buttons {
    display: flex;
    justify-content: center;
    padding: 30px 100px;
  }
  &_reject-revision-field {
    margin-top: 40px;
    margin-bottom: 5px;
  }
  &_cv-notice {
    text-align: center;
    font-family: 'Lato-Italic';
    color: $gray-color-6;
    font-size: 12px;
    display: flex;
    max-width: 500px;
    line-height: 16px;
    align-self: center;
  }
  &_error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
  }
}

.rate-table {
  margin-top: 20px;
  & .rateTable_cell {
    padding: 12px 0;
  }
  & .rateTable_head {
    padding: 5px 0;
  }
  &_container {
    padding: 0 40px;
  }
  &_rate {
    font-family: 'Lato-Light';
    border: none;
  }
  &_rate-input {
    font-family: 'Lato-Light';
    margin: 2px;
  }
  &_title {
    border: none;
    color: inherit;
    background: none;
  }
  &_border-bottom {
    border-bottom: 1px solid $gray-color-4;
  }
  &_link {
    text-transform: inherit;
    font-size: 14px;
    padding-top: 8px;
    justify-content: flex-end;
  }
}

.error-tab > div > span {
  color: $red-color;
  border-color: $red-color;
}

.error-tab > div {
  color: $red-color;
  border-color: $red-color;
}
.error-tab {
  &_label {
    display: flex;
    align-items: center;
    color: $red-color;
  }
}
.error-tab-selected {
  color: white;

  .circleTab_icon {
    background-color: $red-color;
    border-color: $red-color;
    color: white;
    transition: none;
  }
}

.accept {
  &-form {
    padding: 0 40px;
    &_score {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
    &_match-report-button {
      display: flex;
    }
  }
}

.reject-form {
  @extend .accept-form;
}
