@font-face {
  font-family: 'Lato-Black';
  src: url('../fonts/Lato/Lato-Black.ttf');
}
@font-face {
  font-family: 'Lato-BlackItalic';
  src: url('../fonts/Lato/Lato-BlackItalic.ttf');
}
@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato/Lato-Bold.ttf');
}
@font-face {
  font-family: 'Lato-BoldItalic';
  src: url('../fonts/Lato/Lato-BoldItalic.ttf');
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Regular.ttf');
}
@font-face {
  font-family: 'Lato-Italic';
  src: url('../fonts/Lato/Lato-Italic.ttf');
}
@font-face {
  font-family: 'Lato-Light';
  src: url('../fonts/Lato/Lato-Light.ttf');
}
@font-face {
  font-family: 'Lato-LightItalic';
  src: url('../fonts/Lato/Lato-LightItalic.ttf');
}
@font-face {
  font-family: 'Lato-Thin';
  src: url('../fonts/Lato/Lato-Hairline.ttf');
}
@font-face {
  font-family: 'Lato-ThinItalic';
  src: url('../fonts/Lato/Lato-HairlineItalic.ttf');
}
