@import '../../../dependencies/scss/variables.scss';

.icon {
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $yellow-color-1;

  &.bordered {
    border: solid thin white;
  }
}
