@import '../../../dependencies/scss/variables.scss';

.attachment {
  display: flex;
  align-items: center;
  border: 1px solid $gray-color-4;
  padding: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: $gray-color-1;
  }
}

.filename {
  margin-left: 12px;
  font-family: Lato-Bold;
  font-size: 14px;
}

.downloadIcon {
  margin-left: 24px;
}
