.m0 {
  margin: 0;
}

.p0 {
  padding: 0;
}

.mx0 {
  margin: 0 0;
}

.my0 {
  margin: 0 0;
}

.px0 {
  padding: 0 0;
}

.py0 {
  padding: 0 0;
}

.mt0 {
  margin-top: 0;
}

.pt0 {
  padding-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.pb0 {
  padding-bottom: 0;
}

.ml0 {
  margin-left: 0;
}

.pl0 {
  padding-left: 0;
}

.mr0 {
  margin-right: 0;
}

.pr0 {
  padding-right: 0;
}

.m1 {
  margin: 2px;
}

.p1 {
  padding: 2px;
}

.mx1 {
  margin: 0 2px;
}

.my1 {
  margin: 2px 0;
}

.px1 {
  padding: 0 2px;
}

.py1 {
  padding: 2px 0;
}

.mt1 {
  margin-top: 2px;
}

.pt1 {
  padding-top: 2px;
}

.mb1 {
  margin-bottom: 2px;
}

.pb1 {
  padding-bottom: 2px;
}

.ml1 {
  margin-left: 2px;
}

.pl1 {
  padding-left: 2px;
}

.mr1 {
  margin-right: 2px;
}

.pr1 {
  padding-right: 2px;
}

.m2 {
  margin: 4px;
}

.p2 {
  padding: 4px;
}

.mx2 {
  margin: 0 4px;
}

.my2 {
  margin: 4px 0;
}

.px2 {
  padding: 0 4px;
}

.py2 {
  padding: 4px 0;
}

.mt2 {
  margin-top: 4px;
}

.pt2 {
  padding-top: 4px;
}

.mb2 {
  margin-bottom: 4px;
}

.pb2 {
  padding-bottom: 4px;
}

.ml2 {
  margin-left: 4px;
}

.pl2 {
  padding-left: 4px;
}

.mr2 {
  margin-right: 4px;
}

.pr2 {
  padding-right: 4px;
}

.m3 {
  margin: 8px;
}

.p3 {
  padding: 8px;
}

.mx3 {
  margin: 0 8px;
}

.my3 {
  margin: 8px 0;
}

.px3 {
  padding: 0 8px;
}

.py3 {
  padding: 8px 0;
}

.mt3 {
  margin-top: 8px;
}

.pt3 {
  padding-top: 8px;
}

.mb3 {
  margin-bottom: 8px;
}

.pb3 {
  padding-bottom: 8px;
}

.ml3 {
  margin-left: 8px;
}

.pl3 {
  padding-left: 8px;
}

.mr3 {
  margin-right: 8px;
}

.pr3 {
  padding-right: 8px;
}

.m4 {
  margin: 12px;
}

.p4 {
  padding: 12px;
}

.mx4 {
  margin: 0 12px;
}

.my4 {
  margin: 12px 0;
}

.px4 {
  padding: 0 12px;
}

.py4 {
  padding: 12px 0;
}

.mt4 {
  margin-top: 12px;
}

.pt4 {
  padding-top: 12px;
}

.mb4 {
  margin-bottom: 12px;
}

.pb4 {
  padding-bottom: 12px;
}

.ml4 {
  margin-left: 12px;
}

.pl4 {
  padding-left: 12px;
}

.mr4 {
  margin-right: 12px;
}

.pr4 {
  padding-right: 12px;
}

.m5 {
  margin: 16px;
}

.p5 {
  padding: 16px;
}

.mx5 {
  margin: 0 16px;
}

.my5 {
  margin: 16px 0;
}

.px5 {
  padding: 0 16px;
}

.py5 {
  padding: 16px 0;
}

.mt5 {
  margin-top: 16px;
}

.pt5 {
  padding-top: 16px;
}

.mb5 {
  margin-bottom: 16px;
}

.pb5 {
  padding-bottom: 16px;
}

.ml5 {
  margin-left: 16px;
}

.pl5 {
  padding-left: 16px;
}

.mr5 {
  margin-right: 16px;
}

.pr5 {
  padding-right: 16px;
}

.m6 {
  margin: 24px;
}

.p6 {
  padding: 24px;
}

.mx6 {
  margin: 0 24px;
}

.my6 {
  margin: 24px 0;
}

.px6 {
  padding: 0 24px;
}

.py6 {
  padding: 24px 0;
}

.mt6 {
  margin-top: 24px;
}

.pt6 {
  padding-top: 24px;
}

.mb6 {
  margin-bottom: 24px;
}

.pb6 {
  padding-bottom: 24px;
}

.ml6 {
  margin-left: 24px;
}

.pl6 {
  padding-left: 24px;
}

.mr6 {
  margin-right: 24px;
}

.pr6 {
  padding-right: 24px;
}

.m7 {
  margin: 32px;
}

.p7 {
  padding: 32px;
}

.mx7 {
  margin: 0 32px;
}

.my7 {
  margin: 32px 0;
}

.px7 {
  padding: 0 32px;
}

.py7 {
  padding: 32px 0;
}

.mt7 {
  margin-top: 32px;
}

.pt7 {
  padding-top: 32px;
}

.mb7 {
  margin-bottom: 32px;
}

.pb7 {
  padding-bottom: 32px;
}

.ml7 {
  margin-left: 32px;
}

.pl7 {
  padding-left: 32px;
}

.mr7 {
  margin-right: 32px;
}

.pr7 {
  padding-right: 32px;
}

.m8 {
  margin: 48px;
}

.p8 {
  padding: 48px;
}

.mx8 {
  margin: 0 48px;
}

.my8 {
  margin: 48px 0;
}

.px8 {
  padding: 0 48px;
}

.py8 {
  padding: 48px 0;
}

.mt8 {
  margin-top: 48px;
}

.pt8 {
  padding-top: 48px;
}

.mb8 {
  margin-bottom: 48px;
}

.pb8 {
  padding-bottom: 48px;
}

.ml8 {
  margin-left: 48px;
}

.pl8 {
  padding-left: 48px;
}

.mr8 {
  margin-right: 48px;
}

.pr8 {
  padding-right: 48px;
}
