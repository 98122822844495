@import '../../../dependencies/scss/variables.scss';

.viewSwitch {
  border: 1px solid $gray-color-4;
  background-color: $gray-color-4;
  display: flex;
  & > div {
    padding: 2px;
  }
  & .active {
    background-color: white;
  }
}
