@import './variables.scss';
@import './fonts.scss';

body {
  font-family: 'Lato-Light';
  color: $text-color;
  font-size: 15px;
}
// * {
//     color:                    hsla(210, 100%, 100%, 0.9) !important;
//     background:               hsla(210, 100%,  50%, 0.5) !important;
//     outline:    solid 0.25rem hsla(210, 100%, 100%, 0.5) !important;
// }
.dashboard-container {
  padding: 30px $pagePadding;
}
.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-20-30 {
  padding: 20px 30px;
}
.p-t-0 {
  padding-top: 0;
}
.p-t-3 {
  padding-top: 3px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-12 {
  padding-top: 12px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-t-24 {
  padding-top: 24px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-0 {
  padding-bottom: 0;
}
.p-b-2 {
  padding-bottom: 2px;
}
.p-b-3 {
  padding-bottom: 3px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-b-8 {
  padding-bottom: 8px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-12 {
  padding-bottom: 10px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-b-100 {
  padding-bottom: 100px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-l-2 {
  padding-left: 2px;
}
.p-l-4 {
  padding-left: 4px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-l-8 {
  padding-left: 8px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-l-12 {
  padding-left: 12px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-l-18 {
  padding-left: 18px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-24 {
  padding-left: 24px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-0 {
  padding-right: 0;
}
.p-r-2 {
  padding-right: 2px;
}
.p-r-4 {
  padding-right: 4px;
}
.p-r-5 {
  padding-right: 5px;
}
.p-r-6 {
  padding-right: 6px;
}
.p-r-8 {
  padding-right: 8px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-12 {
  padding-right: 12px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-20 {
  padding-right: 20px;
}
.m-0 {
  margin: 0;
}
.m-l-2 {
  margin-left: 2px;
}
.m-l-4 {
  margin-left: 4px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-12 {
  margin-left: 12px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-6 {
  margin-right: 6px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-12 {
  margin-right: 12px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-30 {
  margin-right: 20px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-t-2 {
  margin-top: 2px;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-4 {
  margin-top: 4px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-8 {
  margin-top: 8px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-12 {
  margin-top: 12px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-16 {
  margin-top: 16px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-48 {
  margin-top: 48px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-0 {
  margin-bottom: 0;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-6 {
  margin-bottom: 6px;
}
.m-b-8 {
  margin-bottom: 8px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-12 {
  margin-bottom: 12px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-24 {
  margin-bottom: 24px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.space-2-8 {
  padding: 2px 8px;
}
.space-4-10 {
  padding: 4px 10px;
}
.space-5 {
  padding: 8px 0;
}
.space-8 {
  padding: 8px 0;
}
.space-12 {
  padding: 12px 0;
}
.space-12-15 {
  padding: 12px 15px;
}
.space-10 {
  padding: 10px 0;
}
.space-15 {
  padding: 15px 0;
}
.space-20 {
  padding: 20px 0;
}
.space-30 {
  padding: 30px 0;
}
.space-20-30 {
  padding: 20px 0 30px 0;
}
.space-v-15 {
  padding: 0 15px;
}
.space-v-16 {
  padding: 0 16px;
}
.space-v-5 {
  padding: 0 5px;
}
.cp {
  cursor: pointer;
}
.w90 {
  width: 90px;
}
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.h12 {
  height: 12px;
}
.h22 {
  height: 22px;
}
.m-w-15 {
  min-width: 15px;
}
.m-w-70 {
  min-width: 70px;
}
.m-w-180 {
  min-width: 180px;
}
.m-w-200 {
  min-width: 200px;
}
.m-w-250 {
  min-width: 250px;
}
.m-w-inherit {
  min-width: inherit;
}
.mx-w-15 {
  max-width: 15px;
}
.mx-w-40 {
  max-width: 40px;
}
.mx-w-50 {
  max-width: 50px;
}
.mx-w-70 {
  max-width: 70px;
}
.mx-w-80 {
  max-width: 80px;
}
.mx-w-110 {
  max-width: 110px;
}
.mx-w-160 {
  max-width: 160px;
}
.f-s-9 {
  font-size: 9px;
}
.f-s-10 {
  font-size: 10px;
}
.f-s-13 {
  font-size: 13px;
}
.f-s-14 {
  font-size: 14px;
}
.f-s-15 {
  font-size: 15px;
}
.f-s-21 {
  font-size: 21px;
}
i {
  font-family: 'Lato-LightItalic';
}

b {
  font-family: 'Lato-Bold';
  &.italic {
    font-family: 'Lato-BoldItalic';
  }
}
.bolder {
  font-family: 'Lato';
}
.bold {
  font-family: 'Lato-Bold';
}
.border {
  border: 1px solid $gray-color-4;
}
.border-top-none {
  border-top: none;
}
.border-bottom-none {
  border-bottom: none !important;
}
.border-right-none {
  border-right: none;
}
.border-right {
  border-right: 1px solid $gray-color-4;
}
.border-right-transparent {
  border-right: 1px solid transparent !important;
}
.border-bottom {
  border-bottom: 1px solid $gray-color-4;
}
.underline {
  text-decoration: underline;
}
.text-smaller {
  font-size: 95%;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text- {
  &blue {
    color: $blue-color;
  }
  &red {
    color: $red-color;
  }
  &gray {
    color: $gray-color-6;
  }
}
.bg- {
  &blue {
    background-color: $blue-color;
  }
  &red {
    background-color: $red-color;
  }
  &green {
    background-color: $green-color;
  }
  &yellow {
    background-color: $yellow-color;
  }
  &darkGray {
    background-color: $gray-color-7;
  }
  &gray {
    background-color: $gray-color-6;
  }
  &white {
    background-color: white;
  }
}
.word-break {
  word-break: break-word;
}
.overflowYAuto {
  overflow-y: auto;
}

.overflowHidden {
  overflow: hidden;
}

#launcher {
  right: -80px !important;
  transition: all 0.5s ease-in !important;
}

#launcher:hover {
  right: -16px !important;
  transition: all 0.5s ease-out !important;
}

.sticky {
  position: sticky;
  top: 0px;
}

.display-none {
  display: none;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-flex {
  display: inline-flex;
}

.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}

.upperCase {
  text-transform: uppercase;
}

.line-2 {
  line-height: 1.2;
}
.line-3 {
  line-height: 1.3;
}

.text-decoration-none {
  text-decoration: none;
}
