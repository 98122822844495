@import '../../../dependencies/scss/variables.scss';

.loader {
  display: flex;
  justify-content: center;
  margin: 45px 0;
}

.head {
  display: flex;
  align-items: flex-end;
}

.list {
  padding: 20px;
  padding-top: 30px;
  border: 1px solid $gray-color-4;
  background-color: #fff;
}

.title {
  font-size: 25px;
  font-family: 'Lato-Light';
}

.banner {
  background-color: white;
}
