.container {
  padding: 45px;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Lato-Light';
  font-size: 20px;

  &.centered {
    justify-content: center;
    text-align: center;
  }

  &.centered > .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 0;
  }
}

.closeButton {
  margin-left: 20px;
}

.body {
  position: relative;
  margin-top: 25px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  margin-top: 25px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.hiddenContent {
  visibility: hidden;
  max-height: 300px;
}
