@import '../../../dependencies/scss/variables.scss';

.link {
  font-size: 14px;
  color: $blue-color;
  font-family: 'Lato';
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.inline {
  display: inline;
}
