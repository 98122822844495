@import '../../../../../dependencies/scss/variables.scss';

.container {
  display: flex;
  align-items: center;
}

.menu {
  margin-top: 5px;
  padding: 8px 0;
}

.item {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  font-size: 15px;
  transition: background-color 250ms linear;

  &:hover {
    background-color: $gray-color-3;
  }
}

.icon {
  margin-right: 8px;
  color: $gray-color-6;
}
