.row {
  display: grid;
  grid-template-columns: 60px 1fr repeat(4, 130px) 30px;
  column-gap: 15px;
  padding-right: 4px;

  &.proposal {
    grid-template-columns: 1fr repeat(4, 130px) 30px;
  }
}

.head {
  line-height: 1.3;
  align-items: end;
}

.releasableDocument {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameBox {
  display: flex;
  overflow: hidden;
}
