@import '../../../dependencies/scss/variables.scss';

.statusNote {
  &_text {
    margin-bottom: 8px;
  }
  &_author {
    font-size: 11px;
    font-family: Lato;
    color: $gray-color-6;
    padding-top: 8px;
    border-top: 1px solid $gray-color-4;
  }
}
