@import '../../../../../dependencies/scss/variables.scss';

.table {
  font-size: 14px;
}

.body {
  position: relative;
  display: grid;
  grid-auto-rows: auto 1fr;
  row-gap: 5px;
  max-height: 210px;
  overflow-y: auto;
}

.row {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: $gray-color-1;

  &.hoverable {
    transition: background-color 200ms linear;

    &:hover:not(.highlighted) {
      background-color: $gray-color-3;
    }
  }

  &.highlighted {
    background-color: $secondary-hover-color;
  }

  &.hoverable.highlighted:hover {
    background-color: $blue-color-1;
  }
}

.head {
  padding: 0 10px;
  margin-bottom: 10px;
  align-items: baseline;
}

.title {
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $gray-color-6;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
