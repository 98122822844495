$size: calc(var(--size) * 1px);

.circle {
  width: $size;
  height: $size;
  background: black;
  border-radius: 50%;
  filter: blur(4px);
}

.container {
  position: relative;
  width: calc($size * 7); // 4 dots + 3 have margin
  height: $size;
  opacity: 0.3;
  background: #fff;
  filter: contrast(20);
  padding: calc($size / 2) // for applying contrast effect
}

.dot {
  @extend .circle;
  position: absolute;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}

.dots {
  transform: translateX(calc($size * 2));
  animation: dots 2.8s infinite;

  span {
    @extend .circle;
    display: block;
    float: left;

    &:not(:first-of-type) {
      margin-left: $size;
    }
  }
}

@keyframes dot {
  50% {
    transform: translateX(calc($size * 6));
  }
}

@keyframes dots {
  50% {
    transform: translateX(0);
  }
}
