@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';
.dashboard {
  &-icon {
    font-size: 17px;
    width: 24px;
    background-color: white;
    &-div {
      background-color: white;
      padding: 0px 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }
  &-input {
    color: inherit;
    border-left: none;
    box-shadow: none;
    height: 34px;
    width: 100%;
    padding-left: 0;
    border: none;
    background-color: inherit;
    &::-ms-clear {
      display: none;
    }
    &-div {
      border: 1px solid $gray-color-4;
    }
    &-width {
      width: 35px;
      height: 2px;
    }
    &-group {
      display: flex;
      align-items: center;
    }
    &-style {
      // color: $gray-color-6;
      border-color: $gray-color-4;
      border-radius: 0;
      font-size: 13px;
      font-family: 'Lato-Light';
      cursor: pointer;
      &:focus {
        box-shadow: none;
        border-color: $gray-color-4;
        & .blackBorder {
          border-color: $gray-color-4;
        }
      }
    }
    &-border-white {
      border-right-color: white;
      &:focus {
        border-right-color: white;
      }
    }
    &-highlight {
      background-color: $yellow-light-color;
    }
  }
}

div {
  .dashboard {
    &-input {
      &-disabled {
        background-color: $gray-color-2;
        span {
          background-color: transparent;
        }
      }
    }
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  @include placeholder();
}
::-moz-placeholder {
  /* Firefox 19+ */
  @include placeholder();
}
:-ms-input-placeholder {
  /* IE 10+ */
  @include placeholder();
}
:-moz-placeholder {
  /* Firefox 18- */
  @include placeholder();
}
