@import '../../../../dependencies/scss/variables.scss';

.container {
  border: 1px solid $gray-color-4;
  padding: 20px 15px;

  &.darker {
    background-color: #fbfbfb;
  }
}

.header {
  font-family: 'Lato';
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.items {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}

.label {
  margin-bottom: 3px;
  font-size: 11px;
  font-family: 'Lato-Bold';
  text-transform: uppercase;
}

.link {
  font-size: 15px;
  text-decoration: underline;
}

.info {
  margin-top: 3px;
  font-size: 11px;
  color: $gray-color-6;
  font-family: 'Lato';
}

.loader {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}