@import '../../../dependencies/scss/variables.scss';

.closeChip {
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 15px;
  background-color: $blue-color-1;
  border-radius: 25px;
  margin-right: 5px;
  color: $blue-color-8;
  line-height: 1.1;
}
.label {
  margin-right: 5px;
  font-size: 12px;
  font-family: 'Lato-Bold';
}
.close {
  cursor: pointer;
  display: flex;
}
.disabled {
  color: $gray-color-5;
  background-color: $gray-color-4;
}
