@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';
@import '../../../dependencies/scss/common.scss';

$warning-color: $red-color;

.field {
  margin-bottom: 30px;
  &_question {
    font-family: 'Lato';
    line-height: 16px;
    margin-bottom: 5px;
    font-size: 15px;
  }
  &_label {
    display: flex;
    align-items: baseline;
  }
  &_description {
    margin-bottom: 5px;
    @include description();
  }
  &_warning {
    margin-top: 5px;
    @include description();
    color: $orange-color;
  }
  &_content {
    padding-top: 5px;
    &.disabledPointer {
      @extend .disabledPointer;
    }
  }
  &_optional {
    padding-left: 4px;
    @include placeholder();
  }
  &_requiredWarning {
    color: $warning-color;
    display: flex;
    padding-top: 3px;
    font-size: 12px;
    font-family: 'Lato-Bold';
  }
  &_info {
    margin-left: auto;
    justify-content: flex-end;
  }
  &_datePicker {
    border: 1px solid $gray-color-4;
  }
}

.redForm {
  & .field {
    &_label {
      color: $warning-color;
    }
    &_datePicker {
      border-color: $warning-color;
    }
  }
  & .dashboard-input-div {
    border-color: $warning-color;
  }
  & .checkboxContainer_header {
    border-color: $warning-color;
  }
  & .datepicker-container > div > div {
    border-color: $warning-color;
  }
  & .autocomplete-container > div {
    border-color: $warning-color;
  }
  & .textareaCustom {
    border-color: $warning-color;
  }
}
