@import '../../../dependencies/scss/variables.scss';

$logo-size: 30px;

.header {
  color: white;
  height: $header-hight;
  display: flex;
  align-items: center;
  padding: 0 $pagePadding - 30;
  justify-content: space-between;
  &-menu {
    top: $header-hight !important;
    height: calc(100% - #{$header-hight}) !important;
  }
  &_name {
    font-size: 21px;
    font-family: 'Lato';
  }
  &_sing {
    padding-left: 15px;
  }
  &_icons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &_version {
    font-size: 11px;
    align-items: flex-end;
    padding-bottom: 2px;
    display: flex;
    padding-left: 8px;
  }
  &--atrament {
    background-color: $atrament-dark-color;
  }
  &--brownish {
    background-color: $brownish-dark-color;
  }
  &--manager {
    background-color: $manager-dark-color;
  }
}

.publicHeaderButton {
  border: 1px solid transparent;
  padding: 10px 20px;
  font-family: Lato;
  margin-right: 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ffffff25;
  }
  &-highlighted {
    @extend .publicHeaderButton;
    border-color: white;
  }
}

.logo {
  display: flex;
  align-items: center;
  &_text {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
  }
  &_line {
    width: 70px;
    height: 2px;
    background-color: $blue-color;
    align-self: center;
    &.supplier {
      background-color: $red-color;
    }
    &.manager {
      background-color: $green-color;
    }
  }
  &_image {
    width: $logo-size;
    height: $logo-size;
  }
  &_role {
    font-family: 'Lato-Bold';
    opacity: 0.65;
    font-size: 16px;
    letter-spacing: 4px;
  }
  &_secondaryName {
    padding-bottom: 2px;
    font-size: 14px;
  }
  &_name {
    font-size: 20px;
    font-family: Lato-Light;
    letter-spacing: 1px;
  }
}

$bannerFontSize: 14px;
.headerBanner {
  margin: 0;
  height: 55px;
  display: flex;
  padding: 2px 20px;
  & .banner_header {
    display: none;
  }
  & .banner_text {
    font-size: $bannerFontSize;
  }
  & .banner_button {
    padding: 2px 0;
    font-size: $bannerFontSize;
  }
}

.headerSettings {
  &_buttons {
    border-top: 1px solid $gray-color-4;
    border-bottom: 1px solid $gray-color-4;
  }
  &_button {
    cursor: pointer;
    padding: 10px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
      background-color: $gray-color-3;
    }
  }
  &_links {
    padding: 12px 10px;
    display: flex;
    justify-content: space-around;
    font-size: 13px;
    & > a {
      padding: 4px 8px;
      text-align: center;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      &:hover {
        background-color: $gray-color-3;
      }
    }
  }
  &_circle {
    color: $gray-color-6;
    padding: 4px 0;
  }
}
