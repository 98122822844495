@import '../../../dependencies/scss/variables.scss';

@mixin title() {
  font-family: Lato-Bold;
  font-size: 12px;
  color: $gray-color-6;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 15px;
}

.table {
  display: grid;
  grid-template-columns: 50px repeat(3, 1fr) 100px;
  grid-template-rows: auto;
  padding: 16px 16px 8px 16px;
}

.candidateTable {
  @extend .table;
  grid-template-columns: 50px repeat(4, 1fr) 90px;
}

.allowanceTitle {
  @include title();
}

.title {
  @include title();
  padding: 8px 26px 8px 4px;
  text-align: right;
}

.regularText {
  font-size: 14px;
  text-align: right;
  justify-content: flex-end;
}

.endAlignment {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.controls {
  display: grid;
  grid-template-columns: 1fr 80px 1fr 80px 152px;
  grid-template-rows: auto;
  column-gap: 12px;
  padding: 16px;
  & > * {
    display: flex;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
  }
}

.controlElement {
  max-width: 70px;
}

.candidateControls {
  @extend .controls;
  grid-template-columns: 1fr 120px 1fr 80px 152px;
  background-color: $gray-color-1;
  row-gap: 8px;
}

.tooltip {
  font-family: Lato;
  font-size: 12px;
}

.error {
  font-family: Lato-Bold;
  color: $red-color;
  font-size: 12px;
  line-height: 16px;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 16px;
  padding-bottom: 16px;
}

.resetButton {
  grid-column: 5;
  & > button {
    flex: 1;
  }
}

.revisionAllowance {
  grid-template-columns: 1fr 120px 1fr 75px 152px;
  margin-bottom: 8px;
}
