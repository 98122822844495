@import '../../../dependencies/scss/variables.scss';

.rates {
  font-family: 'Lato-Bold';
  font-size: 35px;
  margin-bottom: 8px;
}
.subtitle {
  font-family: 'Lato';
  color: $gray-color-7;
}
