.item {
  font-family: 'Lato-Light' !important;;
  font-size: 17px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.loading {
  cursor: default !important;
  pointer-events: none !important;

  &:hover, &:focus, &:active {
    background-color: 'transparent' !important;
  };
}
