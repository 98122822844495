// sizes
$header-hight: 66px;
$banner-height: 59px;
$header-hight-with-banner: $header-hight + $banner-height;
$menu-big-width: 265px;
$menu-width: 78px;
$cardSize: 380px;
$gridGap: 20px;
$pagePadding: 50px;
$borderRadius: 0px;
$side-panel-width: 210px;
// primary colors
$text-color: #0f1e25;
// gray colors
$gray-color-1: #fbfbfb;
$gray-color-2: #f6f6f6;
$gray-color-3: #f1f1f1;
$gray-color-4: #e6e6e6;
$gray-color-5: #bfbfbf;
$gray-color-6: #a0a0a0;
$gray-color-7: #8e8e8e;
$gray-color-8: #676767;
// role colors
$atrament-dark-color: #152630;
$atrament-color: #1e3b4d;
$brownish-dark-color: #2c2d2f;
$brownish-color: #414043;
$manager-dark-color: #151521;
$manager-color: #1d1d27;
// secondary colors
$blue-dark-color: #0a72a3;
$blue-color: #109fc6;
$green-color: #00bea1;
$green-light-color: #79da80;
$yellow-color: #efcb4f;
$orange-color: #ef9a4f;
$red-color: #d0314b;
$purple-color: #8f3458;
// extended color palette
// blue
$blue-color-1: #d3ecf3;
$blue-color-2: #a5dae9;
$blue-color-3: #5dc6e2;
$blue-color-4: #29b2d8;
$blue-color-5: #109fc6;
$blue-color-6: #188dad;
$blue-color-7: #0e6f8d;
$blue-color-8: #0e5574;
$blue-color-9: #00415e;
// green
$green-color-1: #cbf0e7;
$green-color-2: #93e0d4;
$green-color-3: #49d1bd;
$green-color-4: #00bea1;
$green-color-5: #04a78e;
$green-color-6: #158876;
$green-color-7: #0a7262;
$green-color-8: #025f50;
$green-color-9: #045145;
// red
$red-color-1: #f5cfd5;
$red-color-2: #edb2bc;
$red-color-3: #dc8896;
$red-color-4: #d7566b;
$red-color-5: #d0314b;
$red-color-6: #a51b32;
$red-color-7: #8a1629;
$red-color-8: #6a0616;
$red-color-9: #5e111d;
// yellow
$yellow-color-1: #f8efd1;
$yellow-color-2: #f9e6a6;
$yellow-color-3: #f9dc7a;
$yellow-color-4: #efcb4f;
$yellow-color-5: #e0bc3f;
$yellow-color-6: #cba72d;
$yellow-color-7: #b19329;
$yellow-color-8: #927220;
$yellow-color-9: #7b5d10;
// hover, focus colors
$primary-hover-color: #0e8fb4;
$primary-focus-color: #0e8fb4;
$secondary-hover-color: #e7f6fb;
$secondary-focus-color: #ceebf5;
// light yellow color - to be removed !!!
$yellow-light-color: #fffde7;
