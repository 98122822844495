@import '@styles/colors';
@import '@styles/spacing';

.header {
  margin-bottom: $spacing-3;

  &.looseGap {
    margin-bottom: $spacing-4;
  }
}

.labelWrapper {
  display: flex;
}

.labelText {
  font-family: Lato;
  font-size: 15px;

  &.error {
    color: $red;
  }
}

.required {
  margin-left: $spacing-1;
  font-weight: bold;
  color: $red
}

.optional {
  margin-left: $spacing-2;
  font-family: Lato-Italic;
  font-size: 13px;
  color: $gray-dark;
}

.helperText {
  margin-top: $spacing-2;
  font-size: 13px;
  font-family: Lato;
  color: $gray-dark;
}

.errorText {
  margin-top: $spacing-3;
  font-family: Lato-Bold;
  font-size: 12px;
  color: $red;
}