@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/colorMixins.scss';

.section {
  padding: 20px 30px;
  border-bottom: solid thin $gray-color-4;
}

.banner {
  height: 12px;
  width: 100%;
  background-color: $gray-color-7;
  @include backgroundColors;
}

.mainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}

.submissionsSection {
  border-bottom: none;
}

.rfq {
  text-align: center;
  font-size: 30px;
  font-family: 'Lato-Bold';
  word-break: break-word;
}

.title {
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
  font-family: 'Lato-Bold';
}
