@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/flexMixins.scss';

.tabs {
  display: flex;
}

.tab {
  position: relative;
  margin-left: 50px;
  padding: 14px 0;
  color: $atrament-color;
  font-size: 13px;
  font-family: 'Lato-Bold';
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    opacity: 0;
    background-color: $gray-color-6;
    transition: opacity 200ms ease-out;
  }
  &:hover::after {
    opacity: 1;
  }
  &.active::after {
    opacity: 1;
    background-color: $atrament-color;
  }
}
