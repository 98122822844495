@import '../../../../dependencies/scss/variables.scss';
@import '../../../../dependencies/scss/flexMixins.scss';

.surveyLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  flex: 1;
  font-size: 14px;
  line-height: 1.3;
  .chips {
    display: flex;
    padding-right: 8px;
  }
  .colorBox {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 8px;
  }
  .lightGrayChip {
    background-color: $gray-color-4;
    color: $gray-color-8;
  }
  .close {
    width: 18px;
  }
  .labelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 8px;
  }
  .label {
    border: 1px dashed white;
    padding: 10px;
    padding-left: 2px;
  }
  .hover {
    border-color: $gray-color-4;
  }
  .edit {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .input {
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.3;
  }
}
