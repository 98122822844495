@import '../../../dependencies/scss/variables.scss';

.historyModal {
  border: 1px solid $gray-color-4;
  max-height: 400px;
  overflow: auto;
  &_content {
    padding: 10px 15px;
  }
  &_item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-color-4;
    & > div {
      flex: 1;
      padding-left: 15px;
    }
    &_info {
      padding-top: 10px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      font-size: 13px;
      &_name {
        font-family: Lato-Bold;
        padding-right: 5px;
      }
    }
  }
  &_revision {
    background-color: $gray-color-2;
    padding: 0 5px 0 5px;
    font-size: 12px;
    margin-top: 5px;
    color: $gray-color-6;
    &_header {
      font-family: Lato-Bold;
      display: flex;
      align-content: center;
      justify-content: space-between;
      &_icon {
        padding: 0 4px;
      }
    }
    &_body {
      font-family: Lato;
      padding: 5px 20px 10px 26px;
      line-height: 1.3;
    }
    &_requestor {
      border-top: 1px solid $gray-color-4;
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}
