@import '../../../../dependencies/scss/variables.scss';

.sectionContent {
    margin-top: 12px;
}

.totalPrice {
    width: 100%;
    box-sizing: border-box;
}

.checkListWrapper {
    flex: 1;
}

.submissionReason {
    padding: 8px;
    border: 1px solid $gray-color-4;
}

.submissionName {
    font-family: 'Lato';
}