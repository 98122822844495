@import '../../../dependencies/scss/variables.scss';

.banner {
  background-color: #fff;
  b {
    font-family: Lato;
  }
}
.publishBanner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 24px;
  font-size: 14px;
}
.publishInfo {
  color: $gray-color-7;
  font-family: Lato-Bold;
  font-size: 12px;
  padding-top: 4px;
}
.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 auto;
}
.linkButton {
  margin-right: 16px;
  text-decoration: none;
  font-size: 14px;
}
.audienceInfo {
  display: inline-flex;
  align-items: center;
}
