@import '../../../dependencies/scss/variables.scss';

.onboarding {
  transition: all 0.5s ease;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 1000px;
  & .wizardStepper {
    padding: 30px 20px;
    justify-content: center;
  }
  &_stepFullContent {
    border: 1px solid $gray-color-4;
    border-left: none;
    flex: 1;
    padding: 20px 50px 40px 50px;
    background-color: white;
  }
  &_stepContent {
    padding-top: 20px;
    margin-top: 15px;
    border: 1px solid $gray-color-4;
    padding: 20px;
  }
  &_subLabel {
    font-family: Lato-Bold;
    font-size: 13px;
    margin-top: -32px;
    margin-left: -10px;
    padding: 0 5px;
    & > svg {
      background-color: white;
      padding-left: 5px;
    }
  }
}

.textContent {
  padding: 0 50px;
  flex: 1;
  justify-content: center;
  padding-top: 10px;
  &_info {
    align-items: center;
  }
  &_header {
    font-size: 25px;
    padding-bottom: 20px;
    padding-top: 30px;
    font-family: Lato;
    text-align: center;
  }
  &_label {
    line-height: 1.2;
    text-align: center;
    padding-bottom: 5px;
  }
}

.purchaseOrderForm {
  & .rateYear_input .dashboard-input-div {
    flex: 1;
  }
  & .rateYear_input .dashboard-input-div {
    padding-left: 10px;
  }
  & .rateYear_input .dashboard-input-style {
    text-align: left;
  }
}
