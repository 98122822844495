@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.filters {
  &Button {
    border: 1px solid $gray-color-4;
    padding: 3px 4px;
    cursor: pointer;
    min-width: 120px;
    &_heading {
      @include placeholder();
      margin: 0 10px 0 3px;
    }
  }
}
