@import '../../../dependencies/scss/variables.scss';

.requestRevisionModal {
  &_warning {
    display: flex;
    align-items: center;
    color: $text-color;
    line-height: 1.2;
  }
}
