@import '../../../dependencies/scss/variables.scss';

.banner {
  background-color: $red-color;
  color: white;
  padding: 8px;
  display: flex;
  align-items: center;
  height: $banner-height;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.text {
  flex: 1;
  font-family: Lato;
  line-height: 1.2;
  text-align: center;
}

.button {
  position: absolute;
  right: 15px;
}
