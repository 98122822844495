.listItem {
  display: flex;
  padding-top: 5px;
  &_bullet {
    padding-right: 10px;
    font-family: Lato-Bold;
  }
  &_content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
