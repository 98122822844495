@import './variables.scss';

@mixin colors {
  &.blue {
    color: $blue-color;
  }
  &.red {
    color: $red-color;
  }
  &.green {
    color: $green-color;
  }
  &.yellow {
    color: $yellow-color;
  }
  &.gray {
    color: $gray-color-4;
  }
  &.darkGray {
    color: $gray-color-7;
  }
}

@mixin backgroundColors {
  &.blue {
    background-color: $blue-color;
  }
  &.red {
    background-color: $red-color;
  }
  &.green {
    background-color: $green-color;
  }
  &.yellow {
    background-color: $yellow-color;
  }
  &.gray {
    background-color: $gray-color-7;
  }
}

@mixin blue {
  color: $blue-color-8;
  background-color: $blue-color-1;
}

@mixin green {
  color: $green-color-7;
  background-color: $green-color-1;
}

@mixin yellow {
  color: $yellow-color-9;
  background-color: $yellow-color-2;
}

@mixin red {
  color: $red-color-7;
  background-color: $red-color-1;
}

@mixin gray {
  color: $gray-color-8;
  background-color: $gray-color-3;
}

@mixin darkGray {
  color: 'white';
  background-color: $gray-color-7;
}

@mixin contrastColors {
  &blue {
    @include blue();
  }
  &green {
    @include green();
  }
  &yellow {
    @include yellow();
  }
  &red {
    @include red();
  }
  &gray {
    @include gray();
  }
  &darkGray {
    @include darkGray();
  }
}
