@import '../../../../../dependencies/scss/variables.scss';

.row {
  display: grid;
  grid-template-columns: 30px 60px 1fr 140px 1fr 100px;
  column-gap: 15px;

  &.proposal {
    grid-template-columns: 30px 1fr 140px 1fr;
  }
}

.report {
  display: flex;
  align-items: center;
}

.rates {
  display: table;
  margin: 0 -6px;
}

.ratesRow {
  display: table-row;
}

.rateCell {
  display: table-cell;
  padding: 2px 6px;
}

.checkbox {
  flex: 1 0 auto;
  padding-left: 16px;
}

.nameBox {
  overflow: hidden;
}

.nameWrapper {
  display: flex;
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;
}
