@import '../../../dependencies/scss/variables.scss';

.resolution {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  background-color: $gray-color-3;
  border-radius: 3px;
  cursor: default;
}
.icon {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}
.resolutionLabel {
  text-align: left;
  max-width: 135px;
  font-family: Lato;
  font-size: 12px;
  line-height: 1.3;
  color: $gray-color-8;
}
.actionButton > span {
  font-size: 13px !important;
}
