@import '~@styles/colors';

.container {
  text-transform: none;
  border-radius: 3px;
  font-family: 'Lato';
  font-size: 14px;
  line-height: 16px;
  box-shadow: none;
  min-width: 120px;

  &:hover {
    box-shadow: none;
  }
  
  &.medium {
    height: 32px;
  }

  &.large {
    height: 36px;
  }
}

.contained:not(:global(.Mui-disabled)) {
  &.primary {
    background-color: $blue;
    &:hover {
      background-color: $blue-dark;
    }
  }

  &.error {
    background-color: $red;
    &:hover {
      background-color: $red-dark;
    }
  }

  &.warning {
    background-color: $yellow;
    &:hover {
      background-color: $yellow-dark;
    }
  }

  &.success {
    background-color: $green;
    &:hover {
      background-color: $green-dark;
    }
  }
}

.outlined:not(:global(.Mui-disabled)) {
  &.primary {
    border-color: $blue;
    color: $blue;
    &:hover {
      border-color: $blue-dark;
    }
  }

  &.error {
    border-color: $red;
    color: $red;
    &:hover {
      border-color: $red-dark;
    }
  }

  &.warning {
    border-color: $yellow;
    color: $yellow;
    &:hover {
      border-color: $yellow-dark;
    }
  }

  &.success {
    border-color: $green;
    color: $green;
    &:hover {
      border-color: $green-dark;
    }
  }
}

.loadingSpinner {
  color: inherit;
}

.loadingText {
  margin-left: 8px;
}