.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  position: absolute;
  right: 0;

  &.relative {
    position: relative;
  }
}
