@import '../../../dependencies/scss/variables.scss';

.revisionTypeRequest {
  position: relative;
  padding: 15px;
  border: 1px solid $gray-color-4;
  &_title {
    font-family: Lato;
    font-size: 15px;
  }
  &_subTitle {
    font-size: 13px;
    margin-top: 5px;
  }
  &_buttons {
    display: flex;
    &--fixed {
      position: absolute;
      padding: 15px;
      top: 0;
      right: 0;
    }
  }
  &_ratesSimulationHeader {
    padding: 15px;
    background-color: $gray-color-2;
  }
  & .rateSimulation_rates {
    // reset border collapsing
    width: calc(100% + 2px);
    margin: -1px;
    margin-top: 0;
  }
  &--error {
    border: 1px solid $red-color;
  }
}
