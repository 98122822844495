@import '../../../dependencies/scss/variables.scss';

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: 1fr;
  row-gap: 5px;
  column-gap: 15px;

  &.noLabels {
    grid-template-columns: none;
  }
  &.noLabels > .years {
    grid-column-start: auto;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(55px, 1fr));
  justify-items: end;
  align-items: center;
  column-gap: 12px;
}

.years {
  grid-column-start: 2;
  align-items: end;
}

.text {
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $gray-color-6;
  letter-spacing: 0.5px;
}

.label {
  @extend .text;
  text-transform: uppercase;
  align-self: center;
}

.year {
  @extend .text;
  margin-bottom: 4px;
  text-align: end;
}

.staticRate {
  font-size: 14px;
}
