@import '~@styles/colors';

.container {
  height: auto;
  font-size: 12px;
  font-family: "Lato-Bold";
  letter-spacing: 0.5px;

  &.red {
    background-color: $red-lightest;
    color: $red-darkest;
  }

  &.blue {
    background-color: $blue-lightest;
    color: $blue-darkest;
  }
  
  &.green {
    background-color: $green-lightest;
    color: $green-darkest;
  }

  &.yellow {
    background-color: $yellow-lightest;
    color: $yellow-darkest;
  }

  &.gray {
    background-color: $gray-light;
    color: $gray-darkest;
  }
}

.label {
  &.small {
    font-size: 10px;
    padding: 4px 6px;
  }

  &.medium {
    font-size: 11px;
    padding: 8px 12px;
  }

  &.large {
    padding: 8px 12px;
  }
}