.container {
  display: flex;
  align-items: center;
  padding: 2px 0;
  cursor: initial;

  & > .currency {
    margin-left: 7px;
  }
}

.button {
  display: flex;
  align-items: center;

  & > .currency {
    margin-left: 2px;
  }
}

.currency {
  font-size: 14px;
}
