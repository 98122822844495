@import '../../../dependencies/scss/variables.scss';

.chip {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
}

.section {
  background-color: white;
  border-left: 3px solid $gray-color-2;
  padding: 16px;
  flex: 1;

  &.red {
    border-left-color: $red-color-5;
  }
  &.yellow {
    border-left-color: $yellow-color-4;
  }
  &.green {
    border-left-color: $green-color-4;
  }
}

.sectionHeader {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-right: 5px;
}

.sectionTitle {
  font-size: 15px;
  font-family: Lato;
}

.issues {
  font-family: Lato;
  color: $gray-color-7;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  padding-left: 8px;
}

.issueDot {
  color: $gray-color-5;
  padding-right: 8px;
}

.penalty {
  min-width: 76px;
  display: flex;
  flex: 1;
}

.impactBox {
  display: flex;
  flex-shrink: 0;
}

.impact {
  font-size: 10px;
  padding: 4px 8px;
  font-family: Lato-Bold;
  letter-spacing: 0.3px;
  border-radius: 15px;
  color: $gray-color-8;
  background-color: $gray-color-2;
  display: flex;
  align-items: center;
}

.impactDots {
  display: flex;
  margin-right: 2px;
}

.impactDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 3px;
  background-color: #d3d3d3;

  &.red {
    background-color: $red-color-5;
  }
  &.yellow {
    background-color: $yellow-color-4;
  }
  &.green {
    background-color: $green-color-4;
  }
}

.warningIcon {
  border-radius: 50%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.red {
    background-color: $red-color-1;
    color: $red-color-7;
  }
  &.yellow {
    background-color: $yellow-color-2;
    color: $yellow-color-9;
  }
}

.issue {
  line-height: 1.3;
  font-size: 14px;
  padding-top: 4px;
}

.goodSections {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.factorsTitle {
  font-family: Lato;
  font-size: 18px;
  padding-bottom: 24px;
}

.factorDescription {
  font-size: 14px;
  padding-bottom: 16px;
}

.allSectionsError {
  font-size: 14px;
  line-height: 1.4;
}
