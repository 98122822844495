.attachments {
  margin-left: -10px;
  margin-bottom: -10px;
}

.attachment {
  display: inline-flex;
  margin-left: 10px;
  margin-bottom: 10px;
}
