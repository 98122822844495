@import '../../../dependencies/scss/variables.scss';

.emailOptions {
  margin-bottom: 5px;
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &_title {
    font-family: 'Lato-Bold';
    font-size: 11px;
    text-transform: uppercase;
    color: $gray-color-6;
    margin-bottom: 8px;
  }
  &_element {
    margin-bottom: 10px;
  }
}
