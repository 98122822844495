@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.container {
  border: 1px solid $gray-color-4;
  background-color: white;
}

.row {
  display: grid;
  grid-template-columns: 55px 2fr 3fr 100px;
  align-items: center;
  row-gap: 15px;
  padding: 15px;

  &:hover {
    @include hoverStyle();
  }
}

.proposalRow {
  display: grid;
  grid-template-columns: 2fr 3fr 100px;
  align-items: center;
  row-gap: 15px;
  padding: 15px;

  &:hover {
    @include hoverStyle();
  }
}

.info {
  display: flex;
  align-items: center;
}

.name {
  font-size: 15px;
  font-family: 'Lato-Bold';
  margin-right: 5px;
}

.supplier {
  font-size: 13px;
  font-family: 'Lato-Italic';
  margin-top: 2px;
}

.date {
  color: $gray-color-6;
  font-size: 11px;
  font-family: 'Lato';
  margin-top: 2px;
}
