@import '../../../../dependencies/scss/variables.scss';

@mixin colors($backgroundColor, $fontColor) {
  background-color: $backgroundColor;
  color: $fontColor;
}

.avatar {
  color: white;
  border-radius: 50%;
  font-family: Lato-Bold;
  border: 1px solid white;
  box-shadow: 2px 1px 12px #00000017;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  &.red {
    @include colors($red-color-1, $red-color-7);
  }
  &.green {
    @include colors($green-color-1, $green-color-7);
  }
  &.blue {
    @include colors($blue-color-1, $blue-color-8);
  }
  &.yellow {
    @include colors($yellow-color-2, $yellow-color-9);
  }
  &.gray {
    @include colors($gray-color-3, $gray-color-8);
  }
}
