$cellHeight: 42px;
$cellHeaderHeight: 20px;

@mixin cell($height) {
  min-height: $height;
  max-height: $height;
  display: flex;
  align-items: center;
}

.rateInputs {
  display: flex;
  &_names {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &_rates {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
  &_cell {
    @include cell($cellHeight);
    &-header {
      @include cell($cellHeaderHeight);
    }
  }
}

.rateYear {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 50px;
  &_year {
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    font-size: 13px;
  }
  &_rate {
    justify-content: flex-end;
    font-size: 13px;
  }
  &_input {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & .dashboard-input-style {
      text-align: right;
      padding: 0;
    }
    & .dashboard-input-div {
      padding-right: 6px;
    }
  }
}

.names {
  &_name {
    padding: 0 10px 0 0;
  }
}
