@import '../../../dependencies/scss/variables.scss';

.wrapper {
  flex: 1;
  display: flex;
  align-self: stretch;
  border: 1px solid $gray-color-4;

  &.error {
    border-color: $red-color;
    color: $red-color;
    & > .value {
      font-family: 'Lato';
    }
    & > .currency {
      background-color: $red-color-1;
      color: $red-color-8;
    }
  }
}

.value {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  background-color: $gray-color-2;
  text-align: right;
  font-size: 13px;
  font-family: Lato-Light;
  padding: 10px 5px;
}

.currency {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: $gray-color-2;
  color: $gray-color-6;
  font-family: 'Lato';
  font-size: 14px;
}
