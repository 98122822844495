@import '../../../dependencies/scss/variables.scss';

.scoreList {
  padding: 10px 0;
  &_item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: all 200ms ease-out;
    &:hover {
      background-color: $gray-color-4;
    }
    &.selected {
      background-color: $blue-dark-color;
      //   background-color: $blue-color;
      //   background-color: $atrament-color;
      color: white;
      font-family: Lato;
    }
  }
}
