body {
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

.ck.ck-content.ck-editor__editable {
  strong {
    font-weight: 700;
  }

  p {
    margin-bottom: 10px;
  }

  .table {
    margin: 1em 0;
  }
}
