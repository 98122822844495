@import '../../../dependencies/scss/variables.scss';

.link {
  font-size: 14px;
  color: $blue-color;
  font-family: 'Lato';
  text-decoration: none;

  &:visited,
  &:focus {
    color: $blue-color;
    text-decoration: none;
  }
  &:hover {
    color: $blue-color;
    text-decoration: underline;
  }
}
