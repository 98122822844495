@import '../../../dependencies/scss/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray-color-6;
  font-size: 14px;
  font-family: 'Lato-Light';

  &.inline {
    flex-direction: row;
    justify-content: center;

    & > .icon {
      margin-right: 8px;
      margin-bottom: 0;
    }
  }

  &.md {
    font-size: 16px;
  }
  &.lg {
    font-size: 18px;
  }
}

.icon {
  margin-bottom: 8px;

  & > svg {
    opacity: 0.7;
  }
}
