@import '../../../dependencies/scss/variables.scss';

.container {
  position: fixed;
  top: $header-hight;
  bottom: 0;
  width: $side-panel-width;
  padding: 20px;
  background-color: $gray-color-1;
  border-right: 1px solid $gray-color-4;
  overflow-y: auto;
}

.closeButton {
  display: flex;
  justify-content: flex-end;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.title {
  font-size: 17px;
  font-family: 'Lato';
}

.clearButton {
  font-size: 13px;
  font-family: 'Lato-Italic';
  color: $gray-color-6;
  text-decoration: underline;
}

.content {
  padding-bottom: 20px;
}

.group {
  margin-top: 30px;
}

.groupTitle {
  margin-bottom: 10px;
  font-family: 'Lato';
}
