@import '../../../dependencies/scss/variables.scss';

.container {
  display: flex;
  align-items: center;
}

.title {
  font-family: 'Lato-Bold';
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.content {
  width: 100%;
}
