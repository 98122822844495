@import '../../../dependencies/scss/variables.scss';

.container {
  display: flex;
  border: thin solid $gray-color-4;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding-left: 10px;
  padding-right: 6px;

  &.disabled {
    background-color: $gray-color-2;
  }
  &.error {
    border-color: $red-color;
  }
}

.input {
  font-size: 13px;
  font-family: "Lato-Light";
  color: inherit;
  border: none;
  background-color: transparent;
  flex: 1 0 auto;
}

.icon {
  display: inline-flex;
  color: $gray-color-6;
}