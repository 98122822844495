@import '../../../../dependencies/scss/variables.scss';

$po-color: $green-color;

.purchaseOrder {
  overflow: hidden;
  position: relative;
  border: 1px solid $gray-color-4;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-left: 10px solid $po-color;
  background-color: white;
  &_container {
    padding: 0 50px;
  }
  &.blue {
    border-left-color: $blue-color;
  }
  &.isInactive {
    border-left-color: $gray-color-6;
    background-color: $gray-color-1;
    & .purchaseOrder_logo {
      opacity: 0.8;
    }
  }
  &_logo {
    width: 50px;
    opacity: 0.9;
  }
  &_header {
    padding: 20px 20px 10px 20px;
    &_title {
      font-size: 21px;
      font-family: Lato-Bold;
      padding-bottom: 2px;
      opacity: 0.9;
    }
    &_subTitle {
      font-family: Lato;
      text-transform: uppercase;
      opacity: 0.9;
    }
  }
  &_money {
    width: 140px;
  }
  &_background {
    position: absolute;
    right: 80px;
    bottom: -75px;
    opacity: 0.05;
    z-index: 0;
  }
  &_foreground {
    top: 0;
    z-index: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
