@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/colorMixins.scss';

.section {
  padding: 20px 30px;
  border-bottom: solid thin $gray-color-4;
}

.banner {
  height: 82px;
  width: 100%;
  background-color: $gray-color-7;
  @include backgroundColors;
}

.content {
  position: relative;
  top: -65px;
}

.scoreSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.score {
  background-color: $gray-color-1;
  border-radius: 50%;
  padding: 10px;
}

.name {
  font-family: 'Lato-Bold';
  font-size: 30px;
  margin-top: 5px;
}

.positionLink {
  font-family: inherit;
  font-size: 15px;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: $atrament-color;
  }
}

.rfq {
  font-family: 'Lato-Bold';
  word-break: break-word;
}

.reportLink {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.textSectionTitle {
  color: inherit;
}
