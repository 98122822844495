@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.rateTable {
  display: table;
  border-collapse: collapse;
  width: 100%;
  text-align: right;
  & * {
    box-sizing: border-box;
  }
  &_row {
    display: table-row;
  }
  &_head {
    display: table-cell;
    min-width: 90px;
    padding: 5px 15px;
  }
  &_cell {
    display: table-cell;
    min-width: 90px;
    padding: 12px 15px;
    font-size: 13px;
    border: 1px solid $gray-color-4;
    font-family: Lato;
  }
  &_symbol {
    width: 8px;
    flex-basis: 8px;
    display: inline-block;
    text-align: left;
    margin-left: 4px;
  }
  &_input {
    display: table-cell;
    padding: 0 15px;
    font-size: 13px;
    font-family: inherit;
    border: 1px solid $gray-color-4;
    &_box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &_element {
      flex: 1 0 auto;
      width: 45px;
      padding: 0;
      color: inherit;
      font-family: inherit;
    }
    &-highlighted {
      background-color: $yellow-light-color;
    }
  }
  &-bordered {
    border: 1px solid $gray-color-4;
  }
  &-left {
    text-align: left;
  }
  &-title {
    @include subtitle();
  }
  &-disabled {
    background-color: $gray-color-2;
    color: $gray-color-6;
  }
  &-error {
    color: $red-color;
    font-family: 'Lato';
  }
}
