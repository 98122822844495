@import '../../../dependencies/scss/variables.scss';

.inputGroup {
  &_item {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }
  &_chip {
    background-color: $blue-color;
    color: white;
    padding: 5px 10px;
    margin-right: 1px;
    border-radius: 25px;
    font-size: 13px;
    font-family: Lato;
  }
  &_close {
    padding-top: 3px;
  }
}
