@import '../../../dependencies/scss/variables.scss';

.title {
  font-family: Lato;
  font-size: 16px;

  &.error {
    color: $red-color;
  }
}

.optional {
  color: $gray-color-6;
  font-family: 'Lato-Italic';
  font-size: 14px;
  margin-left: 4px;
}

.description {
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.3;
}

.content {
  margin-top: 32px;
  font-size: 14px;
}

.errorMessage {
  font-family: 'Lato-Bold';
  font-size: 12px;
  color: $red-color;
}