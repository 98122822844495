@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.draftGrid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
}

$padding: 20px;

.draft {
  border: 1px solid $gray-color-4;
  border-left: 4px solid;
  min-height: 110px;
  position: relative;
  top: 0;
  overflow: hidden;
  background-color: white;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover {
    transform: scale(1.01);
    @include hoverStyle();
  }
  &_link {
    color: $blue-color;
    font-family: Lato;
    display: flex;
    transition: all 200ms ease-out;
    & > svg {
      padding-right: 3px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  &.green {
    border-left-color: $green-color;
  }
  &.blue {
    border-left-color: $blue-color;
  }
  &.red {
    border-left-color: $red-color;
  }
  &.yellow {
    border-left-color: $yellow-color;
  }
  &.orange {
    border-left-color: $orange-color;
  }
  &_date {
    font-size: 11px;
  }
  &_name {
    font-family: Lato-Bold;
    font-size: 17px;
    padding-bottom: 3px;
    width: calc(100% - 10px);
  }
  &_type {
    display: flex;
    flex: 1;
  }
  &_project {
    padding-left: 5px;
    font-style: italic;
  }
  &_settings {
    position: absolute;
    top: $padding;
    right: $padding - 10px;
    z-index: 1;
  }
  &_background {
    position: absolute;
    bottom: -30px;
    right: 0;
    opacity: 0.4;
    z-index: 0;
  }
  &_foreground {
    top: 0;
    z-index: 1;
    padding: $padding;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
