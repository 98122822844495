.privacyBannerContainer {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1220px) {
  .privacyBannerContainer {
    flex-direction: column;
    justify-content: normal;
  }
}
