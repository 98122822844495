@import '../../../dependencies/scss/variables.scss';

$padding: 15px;
.projectRole {
  // background-color: $gray-color-1;
  border: 1px solid $gray-color-4;
  padding: $padding;
  margin-bottom: $padding;
  position: relative;
  &_role {
    margin-bottom: $padding;
  }
  &_projects {
    margin-bottom: 5px;
  }
  &_delete {
    position: absolute;
    right: $padding;
    top: $padding;
    z-index: 1;
  }
  &_alert {
    color: $red-color;
    margin-bottom: $padding;
  }
}
