@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.publishStatus {
  font-size: 12px;
  border-radius: 16px;
  font-family: 'Lato-Bold';
  color: $gray-color-8;
}

.published {
  color: $red-color-7;
}
