@import '../../../dependencies/scss/variables.scss';
.toasts {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 2400;
}
.toast {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Lato;
  background-color: $red-color;
  color: white;
  margin: 10px;
  padding: 10px;
  width: 250px;
  min-height: 40px;
  &_text {
    padding-left: 5px;
    flex: 1 0 0;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}
.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
