@import '../../../dependencies/scss/variables.scss';

.container {
  padding: 45px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-family: 'Lato-Light';
  font-size: 20px;
  margin-right: 20px;
}

.body {
  margin-top: 35px;
}

.oldBody {
  margin-top: 20px;
  padding: 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
