@import '../../../../../dependencies/scss/variables.scss';

$numberOfYears: var(--numberOfYears);

.row {
  display: grid;
  grid-template-columns: 60px 1fr minmax(125px, auto) 1fr 30px;
  grid-auto-columns: auto;
  column-gap: 15px;

  &.proposal {
    grid-template-columns: 1fr minmax(125px, auto) 1fr 30px;
  }
}

.head {
  margin: 0;
}

.years {
  grid-template-columns: repeat($numberOfYears, minmax(55px, 1fr));
  margin-right: 8px;
}

.rates {
  display: grid;
  grid-template-columns: repeat($numberOfYears, minmax(55px, 1fr));
  justify-items: end;
  align-items: center;
  column-gap: 12px;
  margin-right: 8px;
}

.documents {
  overflow: hidden;
}

.error {
  font-family: 'Lato';
  color: $red-color;
}

.errorText {
  @extend .error;
  font-style: italic;
}

.disabledText {
  color: $gray-color-6;
}

.rateTable {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-columns: auto;
  column-gap: 15px;
}

.nameBox {
  display: flex;
  overflow: hidden;
}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;

  &.alignRight {
    justify-content: flex-end;
  }
}
