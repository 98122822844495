@import '../../../dependencies/scss/variables.scss';

.container {
  display: grid;
  grid-template-columns: 1fr;
}

.row {
  display: grid;
  grid-template-columns: 50px 1.5fr 1.5fr 1fr;
  column-gap: 10px;
  padding: 8px;
  border-top: solid thin $gray-color-4;

  &:first-of-type {
    border-top: none;
  }
}

.header {
  font-family: 'Lato-Bold';
  font-size: 11px;
  color: $gray-color-6;
  text-transform: uppercase;
}

.version {
  @extend .header;
  color: $blue-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  font-family: 'Lato';
  color: $red-color;
}
