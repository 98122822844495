@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/flexMixins.scss';

$title-color: $gray-color-6;
.cardSection {
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid $gray-color-4;
  min-height: 30px;
  &_title {
    background-color: $title-color;
    color: white;
    text-transform: uppercase;
    font-family: 'Lato-Bold';
    font-size: 11px;
    padding: 2px 10px 2px 15px;
    position: relative;
    &::after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 0 0;
      border-color: $title-color transparent transparent transparent;
      position: absolute;
      content: '';
      top: 0px;
      right: -15px;
    }
  }
  &_header {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  &_content {
    padding: 0 15px 15px;
  }
  &--bordered {
    border-top: 0;
    border: 1px solid $gray-color-4;
  }
  &--noPadding &_content {
    padding: 0;
  }
}
