@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #e6e6e6;
  padding: 20px;
  background-color: #fff;
}

.grid {
  position: relative;
  display: grid;
  column-gap: 15px;
  padding: 12px 10px;

  &.matchManager {
    // position|status|dueDate|targetRate|nteRate|location|submissions|resolution
    grid-template-columns: minmax(120px, 2fr) 105px 90px 75px 75px minmax(70px, 1fr) 80px minmax(125px, 1.2fr)
  }
  &.projectManager {
    // position|status|dueDate|nteRate|location|submissions|startDate|resolution
    grid-template-columns: minmax(120px, 2fr) 105px 90px 80px minmax(70px, 1fr) 70px 90px minmax(125px, 1.2fr)
  }
  &.partner {
    // position|status|dueDate|targetRate|location|submissions|startDate|resolution
    grid-template-columns: minmax(120px, 2fr) 105px 90px 80px minmax(80px, 1fr) 70px 90px minmax(125px, 1.2fr)
  }
}

.head {
  display: flex;
  align-items: flex-end;
  font-family: 'Lato-Bold';
  font-size: 11px;
  color: $gray-color-6;
  text-transform: uppercase;
  line-height: 1.2;

  &.alignRight {
    text-align: right;
    justify-content: flex-end;
  }
  &.alignCenter {
    text-align: center;
    justify-content: center;
  }
}

.row {
  background-color: $gray-color-1;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  transition-duration: 0.3s;
  transition-property: transform;
  transform: perspective(1px) translateZ(0);

  &:hover {
    @include hoverStyle();
    transform: scale(1.01);
    border-bottom: 1px solid $gray-color-2;
  }

  &:hover .settings {
    opacity: 1;
  }
}

.cell {
  display: flex;
  overflow: hidden;
  align-items: center;
  font-size: 16px;

  &.right {
    justify-content: flex-end;
  }
}

.columnCell {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;

  &.right {
    align-items: flex-end;
  }
}

.statusDate {
  margin-top: 2px;
  line-height: 1.3;
}

.rfq {
  font-size: 14px;
  font-family: 'Lato';
  flex-shrink: 0;
  margin-right: 4px;
}

.projectChip {
  display: block;
}

.locationIcon {
  position: relative;
  top: 1px;
  flex-shrink: 0;
  margin-right: 2px;
}

.currency {
  font-size: 14px;
  margin-left: 2px;
}

.resolution {
  font-family: Lato;
  font-size: 12px;
  color: $gray-color-8;
}

.settings {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  box-shadow: 2px 1px 12px #00000017;
  border-radius: 50%;
  padding: 1px;
  opacity: 0;
  transition: opacity 0.3s linear;
}
