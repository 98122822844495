@import '../../../dependencies/scss/variables.scss';
@import '../../../dependencies/scss/mixins.scss';

.rates {
  display: flex;
}

.rate {
  flex: 1;
  font-size: 13px;
}

.year {
  font-family: 'Lato';
  color: $text-color;
  margin-bottom: 5px;
}
