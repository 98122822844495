@import '../../../dependencies/scss/variables.scss';

.input {
  width: 75px;
  text-align: right;
  font-size: 13px;
  font-family: 'Lato-Light';
  padding: 10px 5px;
  background-color: transparent;
  border: none;

  &.bordered {
    border: 1px solid $gray-color-4;
  }
  &.highlighted {
    background-color: $yellow-light-color;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: $gray-color-2;
  }
  &.readOnly {
    cursor: default;
    background-color: $gray-color-2;
  }
  &.floatLeft {
    text-align: left;
  }
}
