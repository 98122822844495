@import '~@styles/colors';

.filesList {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  margin-top: 8px;
}

.progressBarColor {
  background-color: $blue-light;
}

.progressBarSecondaryColor {
  background-color: $blue-lightest;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border: 2px dashed $gray;
  cursor: pointer;

  &.active {
    border-color: $blue;
  }
  &.disabled {
    background-color: $gray-light;
    cursor: not-allowed;
  }
  &:focus {
    border: solid thin black;
  }
}

.underline {
  text-decoration: underline;
}