@import './variables.scss';
@import './flexMixins.scss';

.header-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-hight;
  z-index: 1;
}
.menu-layout {
  position: fixed;
  top: $header-hight;
  bottom: 0;
  left: 0;
  width: $menu-width;
  z-index: 1;
}
.main-layout {
  position: absolute;
  top: $header-hight;
  width: calc(100% - #{$menu-width});
  height: calc(100% - #{$header-hight});
  left: $menu-width;
  z-index: 0;
  &.full {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.header-full {
    left: 0;
    width: 100%;
  }
}

.display-header-menu {
  display: none;
}

.grid-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: width 0.3s ease 0s;
  &-banner {
    & .main-layout {
      top: $header-hight-with-banner;
      height: calc(100% - #{$header-hight-with-banner});
      &.full {
        top: $banner-height;
        height: calc(100% - #{$banner-height});
      }
    }
    & .header-layout {
      top: $banner-height;
    }
    & .menu-layout {
      top: $header-hight-with-banner;
    }
  }
}

@media screen and (max-width: 785px) {
  .main-layout {
    left: 0;
    width: 100%;
  }
  .menu-layout {
    display: none;
  }

  .display-header-logo {
    display: none;
  }

  .display-header-menu {
    display: block;
    &.menu {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 500;
      display: flex;
      @include flex(1);
      width: 100%;
      height: auto;
      background-color: #00000030;
    }
  }
}

.attachments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
