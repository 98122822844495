@import '@styles/colors';
@import '@styles/spacing';

.label {
  display: inline-flex;
  align-items: center;
  margin-left: -$spacing-2;
  cursor: pointer;

  &.readOnly {
    cursor: initial;
  }
}

.checkbox {
  color: $gray-dark;
  padding: $spacing-2;

  &:global(.Mui-disabled) {
    color: $gray;
  }
}

.content {
  margin-left: $spacing-2;
}